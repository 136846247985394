import React, { useState } from 'react';
import axios from 'axios';
import './ForgotPassword.css';
import Footer from '../../Components/Footer/Footer';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleForgotPassword = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/request-password-reset`, { email });
            setMessage(response.data.message);
        } catch (error) {
            console.error('Error sending password reset email', error);
        }
    };

  return (
    <div>
       <div className="forgot-password-container-main">
       <div className="forgot-password-container-center">
            <h2>Reset Password?</h2>
            <p>Enter your email below to begin the reset process</p>
            <div className="forgot-password-email-container">
            <div className="forgot-password-email-container-input">
                <input
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </div>
                <div className="forgot-password-email-container-button">
                    <button onClick={handleForgotPassword}>Send Reset Link</button>
                </div>
            </div>

            {message && <div className="email-message"><p>{message}</p></div>}
            </div>
        </div>
        <Footer />
    </div>
);
};

export default ForgotPassword;
