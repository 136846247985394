// CustomToolComponent.jsx
import React from 'react';
import ChatTypeDropdown from '../ChatTypeDropDown/ChatTypeDropdown';
import FileUploader from '../../Fileuploader';
import SinglePDFChatArea from '../../SinglePDFChatArea';
import sherpaToolUserInput from '../../../UserInput/sherpaToolUserInput';
import CustomToolUserInput from '../../../UserInput/CustomToolUserInput';

const AdvancedQAComponent = ({
  blobUrl,
  isModalOpen,
  setModalOpen,
  selectedOption,
  setSelectedOption,
  sessionId,
  selectedFileId
}) => {
  return (
    <div className="parent-container">
      <div className="left-content">
        <div className="chat-PDF-container">
          {blobUrl && (
            <iframe src={blobUrl} style={{ width: '100%', height: '100%' }} />
          )}
        </div>
      </div>
      <div className="right-content">
        <div className="helix-chatPage-custom-tool" id="home">
          <div className="chatPage-topMessage">
            <ChatTypeDropdown
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
            />
          </div>
           {isModalOpen && (
          <div className="modal-overlay-custom">
          <div className="custom-tool-info-container">
                <div className="custom-tool-info">
                    <div className="custom-tool-info-title">
                        <h2>How to Use Helix Advanced Document Reader</h2>
                    </div>
                    <div className="custom-tool-info-text">
                        <p><strong>Upload Your Document:</strong> First upload the file you wish to analyze. This step is crucial to initiate your interactive session with the document.</p>
                        <p><strong>Interactive Interface:</strong> Once uploaded, your document will appear on the left side of the screen, with a dedicated chat interface on the right. </p>
                            <p><strong>Engage in Conversation:</strong> Utilize the chat feature to delve into your document. Our advanced tool analyzes the entire context of your PDF, finding connections in information which regular chats may overlook, allowing you to unearth highly specific information, catering to your precise queries. </p>
                        <p><strong>Optimizing Your Experience:</strong> We're dedicated to providing you with accurate and comprehensive responses as quickly as possible. Please note, some queries may take longer to process due to their complexity. Rest assured, we're continuously working to enhance our technology for faster performance.</p>
                    </div>
                </div>
            </div>

            <div className="modal-content-custom">

              <FileUploader />
              <button className="close-modal-btn" onClick={() => setModalOpen(false)}>X</button>
            </div>
          </div>
        )}
          <SinglePDFChatArea />
          <div className="userInput-container">
            <CustomToolUserInput
              sessionId={sessionId}
              openModal={() => setModalOpen(true)}
              selectedOption={selectedOption}
              selectedFileId={selectedFileId}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvancedQAComponent;