// toolActiveReducer.js

const initialState = {
  isToolActive: false,
  toolSessionID: null,
};

const toolActiveReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_TOOL_ACTIVE':
      return {
        ...state,
        isToolActive: true,
      };
    case 'SET_TOOL_INACTIVE':
      return {
        ...state,
        isToolActive: false,
      };
      case 'SET_TOOL_SESSION_ID':
      return {
        ...state,
        toolSessionID: action.sessionID, // Set the session ID
      };
    default:
      return state;
  }
};

export default toolActiveReducer;
