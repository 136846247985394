import React from 'react';
import './CallToAction.css';  // Import your stylesheet
import { Link } from 'react-router-dom';


const CallToActionSection = () => {
  return (
   <div className= "call-to-action-main">
    <div className="call-to-action-container">
      <h2>Ready to Get Started?</h2>
      <p>Try for free by clicking here!</p>
        <Link to="/auth" className="call-to-action-button">Sign Up</Link>
    </div>
    </div>
  );
};

export default CallToActionSection;
