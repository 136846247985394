import React from 'react';
import { useAuth } from '../../../../Components/User_auth/AuthContext';
import axios from 'axios';
import '../ToolBar.css';


export const FileList = ({ isLoading, error, userFiles, selectedFile, handleFileSelect }) => {
  return (
    <div className="individual-files-container">
      <h4>Files</h4>
      {isLoading ? (
        <p>Loading files...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <div className="individual-files">
          {userFiles.length > 0 ? (
            userFiles.map((fileName, index) => (
              <div
                key={index}
                className={`file-container ${selectedFile && selectedFile.file_name === fileName ? 'selected' : ''}`}
                onClick={() => handleFileSelect({ file_name: fileName })}
              >
                {fileName}
              </div>
            ))
          ) : (
            <p>No files found.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default FileList;