import React from 'react';
import { useAuth } from '../../../../Components/User_auth/AuthContext';
import axios from 'axios';
import '../ToolBar.css';
const FileOptions = ({
  selectedFile,
  showDeleteConfirmation,
  handleConfirmDelete,
  setShowDeleteConfirmation,
  handleChatClick,
  tagInput,
  setTagInput,
  handleAddTag
}) => {
  return (
    <div className="files-options-container">
      <div className="file-options-top-container">
        <div className="options-container-title">Options for...</div>
      </div>
      <div className="selected-file-options-title">
        <span className="file-name">{selectedFile && selectedFile.file_name ? selectedFile.file_name : 'No file selected'}</span>
      </div>

      {showDeleteConfirmation ? (
        <>
          <div className="confirm-button-container">
            <button
              className="confirm-button"
              type="button"
              onClick={handleConfirmDelete}
            >
              Confirm?
            </button>
          </div>
          <div className="cancel-button-container">
            <button
              className="cancel-button-delete"
              type="button"
              onClick={() => setShowDeleteConfirmation(false)}
            >
              Cancel
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="chatPDF-button-container">
            <button
              className="chatPDF-button"
              type="button"
              onClick={() => {
                  if (selectedFile && (selectedFile.file_name || typeof selectedFile === 'string')) {
                    handleChatClick(selectedFile.file_name || selectedFile);
                  } else {
                    alert('Please select a file before chatting.');
                  }
                }}
              >
              Chat?
            </button>
          </div>

          <div className="delete-button-container">
            <button
              className="delete-button"
              type="button"
              onClick={() => setShowDeleteConfirmation(true)}
            >
              Delete PDF?
            </button>
          </div>
          <div className="add-tag-container">
            <input
              type="text"
              className="add-tag-input"
              value={tagInput}
              onChange={(e) => setTagInput(e.target.value)}
              placeholder="Enter tag"
            />
            <button className="add-tag-button" type="button" onClick={handleAddTag}>
              Add Tag
            </button>
          </div>
        </>
      )}
    </div>
  );
};
export default FileOptions;