import '../Navbar/Navbar.css';
import './ChatPage.css'
import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../Components/User_auth/AuthContext';
import ProfileIcon from '../../assets/ProfileIcon.png';



const ChatPageNavbar = ({isLoggedIn}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [profileDropDownOpen, setProfileDropDownOpen] = useState(false);
  const { isAuthenticated } = useAuth();
  const { logout } = useAuth(); // Use the logout function from AuthContext
  const navigate = useNavigate(); // Use the navigate function from react-router-dom
  const dropdownRef = useRef(null); // Ref to the dropdown for detecting outside clicks

  const handleProfileClick = () => {
    setProfileDropDownOpen(!profileDropDownOpen); // Toggle dropdown visibility
  };
const handleLinkClick = () => {
        setDropdownOpen(false);
    };

// Toggles the dropdown open state
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);


  // Listen for clicks outside of the dropdown to close it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [dropdownRef]);


const handleManageAccountClick =() => {
    navigate('/manage-account');
  };


  return (
    <div className="top-bar-chat">
       <div className="title-chat gradient__text">Helix AI</div>

        <div className="left-section">
        {isAuthenticated ? (
          // Render Navbar for logged-in users
          <div className="navbar-links desktop-nav">
            <p><Link to="/">Home</Link></p>
            <p><Link to="/pricing">Pricing</Link></p>
            <p><Link to="/chat">Chat</Link></p>
            <p><Link to="/blog">Blog</Link></p>
                {/* Profile dropdown */}
            <div className="profile-dropdown">
              <img src={ProfileIcon} alt="Profile" className="profile-icon" onClick={handleProfileClick} />
              {profileDropDownOpen && (
                <div className="profile-dropdown-content">
                   <button className= "profile-logout-button" onClick={() => {
                       handleManageAccountClick();
                      }}>
                      Manage Account</button>
                </div>
              )}
            </div>
            </div>
        ) : (
          // Render Navbar for non-logged-in users
          <div className="navbar-links desktop-nav">
            <p><Link to="/">Home</Link></p>
            <p><Link to="/pricing">Pricing</Link></p>
            <p><Link to="/chat">Chat</Link></p>
            <p><Link to="/blog">Blog</Link></p>
            <p><Link to="/auth">Log in</Link></p>
            <div className="get-started-container">
              <p><Link to="/auth">Get Started</Link></p>
            </div>
          </div>
        )}

        {/* Dropdown Button */}
        <div className="dropdown" ref={dropdownRef}>
           <button onClick={toggleDropdown}>
             {dropdownOpen ? 'Close' : 'Menu'}
          </button>

          {/* Dropdown Content */}
          {dropdownOpen && (
            <div className="dropdown-content">
              <p><Link to="/" onClick={handleLinkClick}>Home</Link></p>
               {isAuthenticated ? (
                // Dropdown links for logged-in users
                <>
                 <p><Link to="/manage-account" onClick={handleManageAccountClick}>Manage Account</Link></p>
                 <p><Link to="/pricing" onClick={handleLinkClick}>Pricing</Link></p>
                 <p><Link to="/chat" onClick={handleLinkClick}>Chat</Link></p>
                 <p><Link to="/blog" onClick={handleLinkClick}>Blog</Link></p>
                  </>
                  ) : (
                // Dropdown links for non-logged-in users
                <>
                  <p><Link to="/pricing" onClick={handleLinkClick}>Pricing</Link></p>
                  <p><Link to="/chat" onClick={handleLinkClick}>Chat</Link></p>
                  <p><Link to="/blog" onClick={handleLinkClick}>Blog</Link></p>
                  <p><Link to="/auth" onClick={handleLinkClick}>Sign Up</Link></p>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatPageNavbar;