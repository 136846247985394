import react from 'react';
import './IconSection.css';
import AiIcon from '../../../assets/Ai-tag.png';
import ReUploadIcon from '../../../assets/Reupload-tag.png';
import SecurityIcon from '../../../assets/Security-tag.png';
import PersonalIcon from '../../../assets/Personal-tag.png';
import BusinessIcon from '../../../assets/Business-tag.png';

const IconSection = () => {



    return(
        <div className="iconSection-main-container">
            <div className="iconSection-top-container">
                <div className="icon-container-special">
                    <div className="icon-icon">
                    </div>
                    <div className="icon-title">
                        <h3>Any PDF</h3>
                    </div>
                    <div className="icon-text">
                        <p>Upload any PDF, from technical manuals to legal documents.</p>
                    </div>
                </div>
                <div className="icon-container-normal">
                    <div className="icon-icon">
                    <img src={AiIcon} alt="top-icon" className="top-icon" />
                    </div>
                    <div className="icon-title">
                        <h3>Healthcare</h3>
                    </div>
                    <div className="icon-text">
                        <p>Helix Labs develops advanced AI tools for healthcare, empowering doctors and pharmacists to enhance patient care and treatment outcomes. </p>
                    </div>
                </div>
                <div className="icon-container-normal">
                    <div className="icon-icon">
                    <img src={ReUploadIcon} alt="top-icon" className="top-icon-reupload" />
                    </div>
                    <div className="icon-title">
                        <h3>Energy</h3>
                    </div>
                    <div className="icon-text">
                        <p>Helix Labs can help deliver AI-powered data analysis tools for the energy sector, transforming complex datasets into actionable insights for optimized operations and strategic decision-making..</p>
                    </div>
                </div>
                <div className="icon-container-normal">
                    <div className="icon-icon">
                    <img src={SecurityIcon} alt="top-icon" className="top-icon-security" />
                    </div>
                    <div className="icon-title">
                        <h3>Privacy & Security</h3>
                    </div>
                    <div className="icon-text">
                        <p>Protect your data with Helix Labs' on-premise AI solutions. Our custom tools run securely in-house, keeping your valuable information under your control.</p>
                    </div>
                </div>
            </div>
            <div className="icon-bottom-container">
                <div className="icon-bottom-container-left">
                 <div className="icon-icon-bottom">
                 <img src={PersonalIcon} alt="top-icon" className="bottom-icon" />
                    </div>
                    <div className="icon-title-bottom">
                        <h2>Personal</h2>
                    </div>
                    <div className="icon-text-bottom">
                        <p>Helix empowers individuals, allowing them to effortlessly manage and retrieve information from
                         their personal documents, enhancing learning and productivity.</p>
                    </div>
                </div>
                <div className="icon-bottom-container-right">
                <div className="icon-icon-bottom">
                <img src={BusinessIcon} alt="top-icon" className="bottom-icon" />
                    </div>
                    <div className="icon-title-bottom">
                        <h2>Business</h2>
                    </div>
                    <div className="icon-text-bottom">
                        <p>We at Helix can build your business custom AI solutions, allowing you to automate
                        tasks, increase worker productivity, and gain insights from your data.</p>
                    </div>
                </div>
            </div>
        </div>

    );
}
export default IconSection;