// src/components/ControlPanel.js
import React from 'react';

const ControlPanel = ({ attributes, onFilterChange }) => {
  return (
    <div className="control-panel">
      <h3>Filter Nodes</h3>
      <select onChange={(e) => onFilterChange(e.target.value)}>
        <option value="">Select Attribute</option>
        {attributes.map(attr => (
          <option key={attr} value={attr}>{attr}</option>
        ))}
      </select>
    </div>
  );
};

export default ControlPanel;
