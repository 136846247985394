import React, { useState } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import './auth.css';

const PasswordReset = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const location = useLocation();

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Extract token from URL
        const queryParams = new URLSearchParams(location.search);
        const token = queryParams.get('token');

        if (password !== confirmPassword) {
            setMessage("Passwords do not match.");
            return;
        }

        try {
            // Send the token and new password to the server
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/reset-password`, { token, password });
            setMessage(response.data.message);
        } catch (error) {
            if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        setMessage(error.response.data.message);
    } else {
        // Something happened in setting up the request that triggered an Error
        setMessage("An error occurred. Please try again.");
    }
    console.error('Error:', error);
}
    };

    return (
        <div className= "reset-password-container">
            <div className="reset-password-center">
            <h2>Reset Password</h2>
            <form className= "form-login"onSubmit={handleSubmit}>
                <label>
                    New Password:
                    <input
                        className= "input-login"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </label>
                <label>
                    Confirm Password:
                    <input
                        className= "input-login"
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                    />
                </label>
                <button className= "button-login" type="submit">Reset Password</button>
            </form>
            <div className="reset-password-message">
            {message && <p>{message}</p>}
            </div>
        </div>
        </div>
    );
};

export default PasswordReset;