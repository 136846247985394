import React from 'react';
import '../ToolBar.css';

export const TagList = ({ isLoading, error, userTags, selectedTag, handleTagSelect }) => {
  return (
    <div className="individual-tags-container">
      <h4>Tags</h4>
      {isLoading ? (
        <p>Loading tags...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <div className="individual-tags">
          {userTags.length > 0 ? (
            userTags.map((tagName, index) => (
              <div
                key={index}
                className={`tag-container ${selectedTag === tagName ? 'selected' : ''}`}
                onClick={() => handleTagSelect(tagName)}
              >
                {tagName}
              </div>
            ))
          ) : (
            <p>No tags found.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default TagList;