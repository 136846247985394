import { useEffect, useState } from 'react';
import { useCallback } from 'react';
import "./Pricing.css";
import { useLocation } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { useAuth } from '../../Components/User_auth/AuthContext';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';


const CheckoutPage = () => {
  const location = useLocation();
  const [clientSecret, setClientSecret] = useState(null);
  const { isAuthenticated, token } = useAuth();
  const [stripePromise, setStripePromise] = useState(() => loadStripe(process.env.REACT_APP_STRIPE_KEY));

   const fetchClientSecret = useCallback(async () => {
    const params = new URLSearchParams(location.search);
    const planId = params.get('planId');

    let priceId;
    const isDevelopment = process.env.NODE_ENV === 'development';

    if (planId === 'starter') {
      priceId = isDevelopment ? process.env.REACT_APP_STRIPE_PRICE_ID_STARTER_DEV : process.env.REACT_APP_STRIPE_PRICE_ID_STARTER;
    } else if (planId === 'pro') {
      priceId = isDevelopment ? process.env.REACT_APP_STRIPE_PRICE_ID_PRO_DEV : process.env.REACT_APP_STRIPE_PRICE_ID_PRO;
    } else if (planId === 'premium') {
      priceId = isDevelopment ? process.env.REACT_APP_STRIPE_PRICE_ID_PREMIUM_DEV : process.env.REACT_APP_STRIPE_PRICE_ID_PREMIUM;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/create-checkout-session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ priceId, planId })
      });

      const { clientSecret } = await response.json();
      return clientSecret;
    } catch (error) {
      console.error("Error creating checkout session: ", error);
      // Handle error here
      return null;
    }
  }, [location.search]);

  const options = { fetchClientSecret };

useEffect(() => {
  fetchClientSecret();
}, [location.search]); // Runs when `location.search` changes

  return (
     <div>
     <div className="checkout-main">
     <div id="checkout">
      <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={options}
      >
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
    </div>
    <div>
        <h3>there should be a form here</h3>
    </div>
    </div>
  );
};

export default CheckoutPage;
