import react from 'react'
import './about.css'


const About =() => {

return (

    <div className='about-container'>
        <div className='about-intro'>
           <div className="purpose-statement">
              <h1>Our mission at Helix Labs is to create
              AI tools that transform the way you retain and learn new information.</h1>
            </div>
            <h3>Our Values</h3>
        </div>
        <div className='about-intro'>
            <div className='value-divider'>
                <div className='value-item-one'>
                      <h2>INNOVATION</h2>
                      <p>At Helix Labs, Innovation is at the core of who we are. We relentlessly pursue groundbreaking solutions, embracing the latest in AI and data analysis to redefine what's possible. Our dedication to innovation means we're not just following trends; we're setting them, ensuring our clients always stay ahead of the curve.</p>
                    </div>
                    <div className='value-item-two'>
                      <h2>INTEGRITY</h2>
                      <p>Integrity forms the foundation of all we do at Helix Labs. We uphold the highest standards of honesty and transparency in every interaction and decision. Our commitment to integrity ensures that our clients can trust us to manage their data with the utmost respect and confidentiality, fostering relationships built on trust and reliability.</p>
                    </div>
                    <div className='value-item-three'>
                      <h2>EMPOWERMENT</h2>
                      <p>Empowerment is central to our mission at Helix Labs. We empower our clients by providing them with powerful tools and insights that enable informed decision-making and drive success. We also empower our team by encouraging innovation, supporting professional growth, and recognizing individual contributions. Together, we unlock potential and inspire action.</p>
                    </div>
                    <div className='value-item-four'>
                      <h2>COLLABORATION</h2>
                      <p>Collaboration is key to achieving exceptional results at Helix Labs. We believe in the power of collective intelligence and foster a culture where diverse ideas and perspectives are welcomed and valued. By working closely with our clients and within our team, we leverage our combined expertise to solve complex challenges and achieve shared goals.</p>
                    </div>
            </div>
            <div className = 'about-section'>
                <div className = 'about-paragraph'>
                <h1> About Us</h1>
                <p> Helix Labs originated from a collective vision of a family with diverse interests
                and backgrounds, ranging from experts in STEM to business marketing, all sharing the dream
                of transforming how we learn and retain knowledge. Our intuitive platform allows you to construct
                your own custom knowledge base using personal documents, enabling our AI tools to be custom fit for you.
                </p>
                </div>

            </div>
        </div>


        <div className ='about-commitment'>


        </div>

        <div className = 'about-contact'>


        </div>


    </div>
)
};

export default About;