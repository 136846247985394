
//this action is to set the variable true or false to allow
//display
export const setToolActive = () => {
  return {
  type: 'SET_TOOL_ACTIVE',
  };
};
export const setToolInactive = () => {
  return {
  type: 'SET_TOOL_INACTIVE',
  };
};
export const setToolSessionID = (sessionID) => {
  return {
  type: 'SET_TOOL_SESSION_ID',
  sessionID,
  };
}