// AuthContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';

export const AuthContext = createContext({
  isAuthenticated: false,
  setIsAuthenticated: () => {},
  token: null,
  setToken: () => {}
});

const validateTokenWithServer = async (token) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/token/validate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });

    if (response.ok) {
      const data = await response.json();
      return data.valid; // Assuming the backend returns { valid: true } or { valid: false }
    } else {
      // Handle cases where the server responds with an error (e.g., token expired)
      console.error('Token validation failed:', response.statusText);
      return false;
    }
  } catch (error) {
    console.error('Error validating token:', error);
    return false;
  }
};

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [token, setToken] = useState(localStorage.getItem('token'));

useEffect(() => {
  const validateToken = async () => {
    if (token) {
      // Placeholder for token validation logic
      // This should ideally be an API call to your backend to verify the token's validity
      const isValid = await validateTokenWithServer(token);
      setIsAuthenticated(isValid);
      if (!isValid) {
        // If the token is invalid, clear it from localStorage and update state
        localStorage.removeItem('token');
        setToken(null);
      }
    } else {
      setIsAuthenticated(false);
    }
  };

  validateToken();
}, [token]);


const login = (newToken) => {
    localStorage.setItem('token', newToken);
    setToken(newToken);
  };
 // Function to handle logout
  const logout = () => {
    localStorage.removeItem('token'); // Remove token from storage
    setToken(null);                   // Reset token state
    setIsAuthenticated(false);        // Update authentication state
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, token, setToken, logout, login }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
