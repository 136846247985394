import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './UserInput.css';
import uploadIcon from '../../assets/PDF-upload.png'
import Submit from '../../assets/Submit.png'
import { useAuth } from '../../Components/User_auth/AuthContext';
import {useDispatch} from 'react-redux';
import { addChatPDFMessage, addChatPDFResponse } from '../../redux/actions/splitChatActions'; // Adjust the import path as needed
import { useSelector } from 'react-redux';

const adjustHeight = (element) => {
  element.style.height = '30px'; // Reset the height to calculate scrollHeight properly
  element.style.height = `${Math.min(element.scrollHeight, 300)}px`;
  };


function CustomToolUserInput({  contextResponse, openModal, selectedOption, selectedFileId }) {
  const [chatPDFMessage, setChatPDFMessage] = useState('');
  const { token } = useAuth();
  const dispatch = useDispatch(); // Use useDispatch hook
  const toolSessionID = useSelector(state => state.toolActive.toolSessionID);

  useEffect(() => {
    const textarea = document.querySelector('.message-input');
    if (textarea) {
      adjustHeight(textarea);
    }
  }, [chatPDFMessage]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Clear the input immediately after form submission.
    setChatPDFMessage('');

    // Add the message to the displayed messages.
    dispatch(addChatPDFMessage(chatPDFMessage)); // Dispatch the action to add the message to the store
    console.log('chatPDFMessage:', chatPDFMessage);
    try {
     const response = await axios.post(`${process.env.REACT_APP_API_URL}/helix-chat`,
    {
      message: chatPDFMessage,
      session_id: toolSessionID,
    },
        { headers: { 'Authorization': `Bearer ${token}` } }
      );
        dispatch(addChatPDFResponse(response.data)); // Dispatch the action to add the response to the store
        } catch (error) {
          console.error('Error:', error);
          let errorMessage = "An error occurred, please try again later.";

         if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              if (error.response.status === 500) {
                errorMessage = "An error occurred, try resubmitting your question again";
              } else if (error.response.status === 401) {
                // Handle 401 Unauthorized error
                errorMessage = "Please refresh your page and log in again.";
              }
            } else if (error.request) {
              // The request was made but no response was received
              errorMessage = "Network Error. Please check your connection and try again.";
            } else {
              // Something happened in setting up the request that triggered an Error
              errorMessage = "Error setting up the request.";
            }


            dispatch(addChatPDFResponse({ message: errorMessage, type: 'error' })); // Dispatch the action to add the error message to the store
      console.log('Error response:', error.response); // Log the error response
    }
  };

  // html
  return (
    <div className="userInput-root">
      <form onSubmit={handleSubmit}>
        <div className="input-group">
          {/* Button with the PDF_PNG icon */}
          <div className= "pdf-button-container">
          <button className="pdf-button" type="button" onClick={openModal}>
            <img src={uploadIcon} alt="uploadIcon" />
          </button>
          </div>
         <textarea
            className="message-input"
            type="text"
            value={chatPDFMessage}
            onChange={(e) => {
              setChatPDFMessage(e.target.value);
              adjustHeight(e.target);
            }}
            onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey && chatPDFMessage.trim()) {
                    e.preventDefault();
                    handleSubmit(e);
                  }
                }}
            placeholder="Enter your message"
        />

          {/* The button is disabled if the trimmed message is empty */}
          <button className="input-button" type="submit" disabled={!chatPDFMessage.trim()}>
            <img src={Submit} alt="SubmitIcon" />
          </button>
        </div>
      </form>
    </div>
  );
};

export default CustomToolUserInput;