import React from 'react';
import './FeaturesSection.css'; // import your stylesheet
import featureImageOne from '../../../assets/feature-one-image.jpeg';
import featureImageTwo from '../../../assets/feature_pic_2.jpeg';
import featureImageThree from '../../../assets/circlesDark.jpeg';


const FeaturesSection = () => {
  return (
    <div className="features-container">
      <div className="key-features-title">
        Blog
      </div>
      <div className="features">
        <div className="feature feature-one">
        <a href="/blog/the-age-of-hyperproductivity">
          <img src={featureImageOne} alt="Easy Integration" />
        </a>
          <h3>How AI can Increase Productivity</h3>
          <p>How the intelligence revolution is about to change everything.</p>
        </div>
        <div className="feature feature-two">
        <a href="/blog/top-2024-ai-predictions">
          <img src={featureImageTwo} alt="High Performance" />
        </a>
          <h3>Top 2024 AI Predictions</h3>
          <p>Can 2024 possibly top 2023 in AI acceleration?</p>
        </div>
        <div className="feature feature-three">
        <a href="/blog/navigating-the-data-engineerings-next-frontier">
          <img src={featureImageThree} alt="ChatGPT" />
        </a>
          <h3>Engineering's Next Frontier</h3>
          <p>How AI will change the Engineering field.</p>
        </div>
      </div>
    </div>
  );
};


export default FeaturesSection;
