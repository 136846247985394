
import React, { useState } from 'react';
import './AIsolutions.css';
import axios from 'axios';
import Footer from '../../../Components/Footer/Footer';

const CustomAISolutions = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/contact`, formData);
      alert(response.data.message);
      setFormData({ name: '', email: '', message: '' });
    } catch (error) {
      alert('An error occurred. Please try again.');
    }
  };

  return (
  <div>
    <div className="custom-ai-solutions">


      <main>
        <section className="hero">
          <div className="hero-content">
            <h1>
              <span className="hero-line">Build out your</span>
              <span className="hero-line highlight">AI capabilities</span>
              <span className="hero-line">with our top tier</span>
              <span className="hero-line"> AI tool builders</span>
            </h1>
            <p className="hero-subtitle">Boost your business results by automating and optimizing processes</p>
            <a href="#contact" className="cta-button">CONTACT OUR EXPERTS</a>
          </div>
        </section>

        <section className="services">
          <h2 className="services-title">Our AI Solutions</h2>
          <div className="service-grid">
            <div className="service-item">
              <div className="service-icon">
                <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                  <circle cx="12" cy="12" r="10"></circle>
                  <line x1="14.31" y1="8" x2="20.05" y2="17.94"></line>
                  <line x1="9.69" y1="8" x2="21.17" y2="8"></line>
                  <line x1="7.38" y1="12" x2="13.12" y2="2.06"></line>
                  <line x1="9.69" y1="16" x2="3.95" y2="6.06"></line>
                  <line x1="14.31" y1="16" x2="2.83" y2="16"></line>
                  <line x1="16.62" y1="12" x2="10.88" y2="21.94"></line>
                </svg>
              </div>
              <h3>AI Roadmap</h3>
              <p>Gain a competitive edge with our expert guidance. We'll help you navigate the AI landscape and identify the most valuable use cases for your business.</p>
              <a href="#" className="learn-more">Learn More</a>
            </div>
            <div className="service-item">
              <div className="service-icon">
                <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                  <polygon points="12 2 2 7 12 12 22 7 12 2"></polygon>
                  <polyline points="2 17 12 22 22 17"></polyline>
                  <polyline points="2 12 12 17 22 12"></polyline>
                </svg>
              </div>
              <h3>AI Implementation</h3>
              <p>Turn your AI strategy into reality. Our team will help you integrate cutting-edge AI solutions seamlessly into your existing workflows.</p>
              <a href="#" className="learn-more">Learn More</a>
            </div>
            <div className="service-item">
              <div className="service-icon">
                <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                  <path d="M18 3a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3 3 3 0 0 0 3-3 3 3 0 0 0-3-3H6a3 3 0 0 0-3 3 3 3 0 0 0 3 3 3 3 0 0 0 3-3V6a3 3 0 0 0-3-3 3 3 0 0 0-3 3 3 3 0 0 0 3 3h12a3 3 0 0 0 3-3 3 3 0 0 0-3-3z"></path>
                </svg>
              </div>
              <h3>AI Optimization</h3>
              <p>Maximize the potential of your AI systems. We'll fine-tune your AI models and processes to deliver optimal performance and ROI.</p>
              <a href="#" className="learn-more">Learn More</a>
            </div>
          </div>
        </section>


      <section className="introduction">
        <div className="intro-content">
          <h2>Custom AI Solutions</h2>
          <p>Our tailored AI solutions are designed to address your unique business challenges, propelling you ahead in the age of artificial intelligence.</p>
        </div>
        <div className="intro-features">
          <div className="intro-feature">
            <div className="feature-icon">🚀</div>
            <h3>Accelerate Growth</h3>
            <p>Boost your business performance with AI-driven insights and automation.</p>
          </div>
          <div className="intro-feature">
            <div className="feature-icon">💡</div>
            <h3>Innovate Faster</h3>
            <p>Stay ahead of the curve with cutting-edge AI technologies and methodologies.</p>
          </div>
          <div className="intro-feature">
            <div className="feature-icon">🛠️</div>
            <h3>Tailored Solutions</h3>
            <p>Get AI solutions customized to your specific industry and business needs.</p>
          </div>
        </div>
      </section>

             <section id="contact" className="contact">
          <div className="contact-content">
            <h2>Ready to Transform Your Business?</h2>
            <p>Take the first step towards AI-driven success. Schedule a consultation with our experts today.</p>
            <form className="contact-form" onSubmit={handleSubmit}>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Your Name"
                required
              />
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Your Email"
                required
              />
              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                placeholder="Tell us about your AI needs"
                required
              ></textarea>
              <button type="submit" className="cta-button">Get Expert Advice</button>
            </form>
          </div>
          <div className="contact-image">
            {/* Replace with an actual image when available */}
            <div className="placeholder-image">  </div>
          </div>
        </section>
    </main>

      <footer>
        {/* Footer component would go here */}
      </footer>
    </div>
        <Footer/>
    </div>
  );
};

export default CustomAISolutions;