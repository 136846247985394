import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import './Chat.css'
import { useAuth } from '../../Components/User_auth/AuthContext';
import { useSelector } from 'react-redux';

const SinglePDFChatArea = ({ }) => { // Corrected casing here
  const chatEndRef = useRef(null);
  const chatPDFMessages = useSelector((state) => state.chat.chatPDFMessages);
  const chatPDFResponses = useSelector((state) => state.chat.chatPDFResponses);
  const isToolActive= useSelector((state) => state.toolActive.isToolActive);

  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatPDFResponses]); // Corrected casing here


if (!Array.isArray(chatPDFResponses)) {
 return <div>loading...</div>;
}
  return (
    <div className="chatArea">
    {/* Conditionally render the "Query your knowledge base" message */}
      {chatPDFMessages.length === 0 && (
        <div className="topMessage">
         {isToolActive ? (
      <p>Helix Custom Tools</p>
    ) : (
      <p>Query your knowledge base</p>
    )}
        </div>
      )}
      {chatPDFMessages.map((msg, index) => (
        <div key={index}>
           <p><span className="user-text">You:</span> {msg}</p>
          {chatPDFResponses[index] ? (
          <div>
            <p><span className="helix-text">Helix:</span></p>
              <ReactMarkdown>{chatPDFResponses[index].response}</ReactMarkdown>
              {/* Check for page_number and render if not null */}
                {chatPDFResponses[index].page_number && (
                  <p>Page Number: {chatPDFResponses[index].page_number}</p>
                )}
              </div>
            ) : (
            <div className="response-placeholder">
            <p><span className="helix-text">Helix:</span> Loading response...</p>
            </div>
          )}
        </div>
      ))}
      <div ref={chatEndRef} />
    </div>
  );
};

export default SinglePDFChatArea;
