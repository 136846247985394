// actions.js

// Action Types these are the types of actions that can be dispatched when
// messages and responses are received
export const ADD_MESSAGE = 'ADD_MESSAGE';
export const ADD_RESPONSE = 'ADD_RESPONSE';

// Action Creators
export const addMessage = message => ({
  type: ADD_MESSAGE,
  payload: message,
});

export const addResponse = response => ({
  type: ADD_RESPONSE,
  payload: response,
});

// Action Types
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES';
export const CLEAR_RESPONSES = 'CLEAR_RESPONSES';

// Action Creators
export const clearMessages = () => ({
  type: CLEAR_MESSAGES,
});

export const clearResponses = () => ({
  type: CLEAR_RESPONSES,
});
