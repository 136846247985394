// Assume initial import and initialState setup here
import {
  ADD_MESSAGE,
  ADD_RESPONSE,
  CLEAR_MESSAGES,
  CLEAR_RESPONSES,
} from '../actions/chatActions';
import {
  ADD_CHAT_PDF_MESSAGE,
  ADD_CHAT_PDF_RESPONSE,
  CLEAR_CHAT_PDF_MESSAGES,
  CLEAR_CHAT_PDF_RESPONSES
} from '../actions/splitChatActions';


const initialState = {
  messages: [],
  responses: [],
  chatPDFMessages: [],
  chatPDFResponses: [],
  // Additional state as needed
};

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_MESSAGE:
      return { ...state, messages: [...state.messages, action.payload] };
    case ADD_RESPONSE:
      return { ...state, responses: [...state.responses, action.payload] };
    case CLEAR_MESSAGES:
      return { ...state, messages: [] };
    case CLEAR_RESPONSES:
      return { ...state, responses: [] };
    case ADD_CHAT_PDF_MESSAGE:
      return { ...state, chatPDFMessages: [...state.chatPDFMessages, action.payload] };
    case ADD_CHAT_PDF_RESPONSE:
      return { ...state, chatPDFResponses: [...state.chatPDFResponses, action.payload] };
    case CLEAR_CHAT_PDF_MESSAGES:
      return { ...state, chatPDFMessages: [] };
    case CLEAR_CHAT_PDF_RESPONSES:
      return { ...state, chatPDFResponses: [] };
    // Handle other actions as necessary
    default:
      return state;
  }
};

export default chatReducer;
