// src/reducers/emailReducer.js
import { SET_EMAIL } from '../actions/emailActions';
// src/redux/reducers/emailReducer.js

const initialState = {
  email: '',
};

const emailReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_EMAIL':
      return {
        ...state,
        email: action.payload,
      };
    default:
      return state;
  }
};

export default emailReducer;
