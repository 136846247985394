import React, { useState, useEffect } from 'react';
import './ToolBar.css';
import axios from 'axios';
import { useAuth } from '../../../Components/User_auth/AuthContext';
import {useDispatch} from "react-redux";
import {clearMessages, clearResponses} from "../../../redux/actions/chatActions";
import { clearChatPDFMessages, clearChatPDFResponses } from '../../../redux/actions/splitChatActions'; // Adjust the import path as necessary
import { useSelector } from 'react-redux';
import { setToolActive, setToolInactive } from '../../../redux/actions/toolActions'; // Adjust the path as necessary
import { setSherpaToolActive, setSherpaToolInactive } from '../../../redux/actions/sherpaToolActions'; // Adjust the path as necessary
import {clearBlobUrl} from "../../../redux/actions/PDFActions";
import FileList from "./ToolbarFilesComponents/FileList";
import FileOptions from "./ToolbarFilesComponents/FileOptions";

import FilesInTag from "./ToolbarTagComponents/FilesInTags";
import TagList from "./ToolbarTagComponents/TagList";
import TagOptions from "./ToolbarTagComponents/TagOptions";

import CustomToolsList from "./CustomToolComponents/CustomToolsList";


const UserFiles = ({ setModalOpen, onFileSelect, singlePDFLayout,
 setSinglePDFLayout, setSelectedFileId,setIsToolBarOpen,isToolBarOpen, selectedIcon, userFiles,
 isLoading, filesError, isTagsLoading, userTags, fetchUserTags, userFilesWithinTag, setUserFilesWithinTag,
 setSelectedFile, selectedFile, customTools, selectedTool,fetchCustomTools,setSelectedTool, isLawsuitToolActive, setIsLawsuitToolActive}) => {

const [error, setError] = useState(null);
const [tagsError, setTagsError] = useState(null);

const { token } = useAuth();
const [tagInput, setTagInput] = useState('');
const [isContainerVisible, setContainerVisible] = useState(true);
const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
const [isInCompany, setIsInCompany]=useState(false); //use state to allow the custom tools box to open (conditional rendering)
const [companyTools, setCompanyTools] = useState([]);
const [isCustomToolsVisible, setIsCustomToolsVisible] = useState(false); // New state for managing custom tools visibility
const [isUploadShown, setIsUploadShown] = useState(false);
const [tags, setTags] = useState([]);
const [selectedTagFiles, setSelectedTagFiles] = useState([]);
const [selectedTag, setSelectedTag] = useState(null);
const [allFiles, setAllFiles] = useState([]);
const [displayedFiles, setDisplayedFiles] = useState([]);
const [files, setFiles] = useState([]);
const [fetchedTags, setFetchedTags] = useState([]);
// Global varaiables being used
const dispatch = useDispatch();
const isToolActive = useSelector(state => state.toolActive.isActive);
const isSherpaToolActive = useSelector(state => state.sherpaTool.isActive);


const [filesInTag, setFilesInTag] = useState([]);
const [isFileLoading, setIsFileLoading] = useState(false);
const [fileError, setFileError] = useState(null);

const [isFilesWithinTagsLoading, setIsFilesWithinTagsLoading] = useState(false);
const [filesWithinTagsError, setFilesWithinTagsError] = useState(null);



useEffect(() => {
  if (!isToolBarOpen) {
    // Reset visibility states when toolbar is closed
    setIsUploadShown(false);
    setIsCustomToolsVisible(false);
    setContainerVisible(false); // Assuming this controls the visibility of the options container
    // Reset any other states as necessary
  }
}, [isToolBarOpen]); // Add isToolBarOpen to the dependency array

const handleFileSelect = (file) => {
  setSelectedFile(prevSelectedFile => {
    const newSelectedFile = prevSelectedFile && prevSelectedFile.file_name === file.file_name
      ? null
      : {
          id: file.id,
          file_name: file.file_name,
          // Include any other necessary properties
        };

    console.log('Selected file:', newSelectedFile);
    return newSelectedFile;
  });
};

  const handleTagSelect = (tagName) => {
  console.log('Tag selected:', tagName);

  setSelectedTag(prevTag => {
    const newTag = prevTag === tagName ? null : tagName;
    console.log('New selected tag:', newTag);

    if (newTag) {
      fetchFilesByTag(newTag);
    } else {
      setUserFilesWithinTag([]);
    }

    return newTag;
  });
};
const clearFileSelection = () => {
  setSelectedFile(null);
};
  const fetchFilesByTag = async (tagName) => {
  setIsFilesWithinTagsLoading(true);
  setFilesWithinTagsError(null);
   try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/get-user-tagged-file`, {
      params: { tag: tagName },
      headers: { 'Authorization': `Bearer ${token}` }
    });

    // Check if response.data.files exists and is an array
    if (Array.isArray(response.data.files)) {
      setUserFilesWithinTag(response.data.files);
    } else {
      console.error('Unexpected response format:', response.data);
      setFilesWithinTagsError('Received unexpected data format from server.');
    }
  } catch (error) {
    console.error('Error fetching files for tag:', error);
    setFilesWithinTagsError('Failed to fetch files. Please try again.');
  } finally {
    setIsFilesWithinTagsLoading(false);
  }
};


  const handleChatClick = async (fileId) => {
    console.log('Chat button clicked for file:', fileId);
    try {
      setSelectedFileId(fileId);
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/individual-download-file`, {
        headers: { 'Authorization': `Bearer ${token}` },
        params: { file_name: fileId },
        responseType: 'blob'
      });
      console.log('File download response:', response);
      const blobUrl = URL.createObjectURL(response.data);
      onFileSelect(blobUrl);
      setIsToolBarOpen(false);
      dispatch(clearChatPDFMessages());
      dispatch(clearChatPDFResponses());
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  const handleAddTag = async () => {
  console.log('Selected File for adding tag:', selectedFile);
  console.log('Tag to be added:', tagInput);

  const payload = {
    file_id: selectedFile,
    tag_name: tagInput
  };
  console.log('Payload for adding tag:', payload);

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/add_tag`,
      payload,
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    );

    console.log('Response from add_tag:', response.data);

    if (response.data) {
      setTagInput('');
      console.log('Tag added successfully');
    }
  } catch (error) {
    console.error("Error adding tag:", error.response?.data || error.message);
  }
};





 const handleToolSelect = (toolName) => {
  setSelectedTool(toolName);
  // Deactivate all tools first
  dispatch(setToolInactive());
  dispatch(setSherpaToolInactive());

  if (toolName === 'Advanced QA') {
    dispatch(setToolActive());
    setIsToolBarOpen(!isToolBarOpen);
  } else if (toolName === 'Sherpa (beta)') {
    dispatch(setSherpaToolActive());
    setIsToolBarOpen(!isToolBarOpen);
  } else if (toolName === 'Lawsuit Tool') {
    setIsLawsuitToolActive(!isLawsuitToolActive);
    setIsToolBarOpen(!isToolBarOpen);
  }
  // Add more conditions here for other tools if needed
};


  const hasFiles = userFiles.length > 0 && userFiles[0] !== "No Files Uploaded";


    const handleConfirmDelete = async () => {
    try {
        const { file_name } = selectedFile;

        const response = await axios.post(`${process.env.REACT_APP_API_URL}/delete`, {
            filename: file_name // Only send the file_name property
        }, {
            headers: { 'Authorization': `Bearer ${token}` },
        });

        if (response.data) {
            console.log("File deleted successfully", selectedFile);
            setContainerVisible(false); // Close the options container
        }
    } catch (error) {
        // Handle error (e.g., notify the user)
        console.error("Error deleting file:", error);
    }
};
const handleConfirmDeleteTag = async (fileName, tagName) => {
  console.log('Removing tag from file:', fileName);
  console.log('Tag to be removed:', tagName);

  const payload = {
    file_id: { file_name: fileName },
    tag_name: tagName
  };

  console.log('Payload for removing tag:', payload);

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/remove_tag`,
      payload,
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    );

    console.log('Response from remove_tag:', response.data);

    if (response.status === 200) {
      console.log('Tag removed successfully');
      setShowDeleteConfirmation(false);
      await fetchUserTags();
      setSelectedFile(null);
      setSelectedTag(null);
      // You might want to update your UI state here
    }
  } catch (error) {
    console.error("Error removing tag:", error.response?.data || error.message);
    // Handle the error appropriately in your UI
  }
};



return (
   <div className="main-toolbar-container">
      <div className="main-toolbar-background">
        {selectedIcon === 'files' && (
          <div className="files-section">
            <div className="files-title">
              <h2>Files</h2>
            </div>
            <FileOptions
              selectedFile={selectedFile}
              showDeleteConfirmation={showDeleteConfirmation}
              handleConfirmDelete={handleConfirmDelete}
              setShowDeleteConfirmation={setShowDeleteConfirmation}
              handleChatClick={handleChatClick}
              tagInput={tagInput}
              setTagInput={setTagInput}
              handleAddTag={handleAddTag}
            />
            <FileList
              isLoading={isLoading}
              error={error}
              userFiles={userFiles}
              selectedFile={selectedFile}
              handleFileSelect={handleFileSelect}
            />
          </div>
        )}

    {selectedIcon === 'tags' && (
          <div className="tags-section">
            <div className="tags-title">
              <h2>Tags</h2>
            </div>
            <TagOptions
              selectedTag={selectedTag}
              selectedFile={selectedFile}
              handleChatClick={handleChatClick}
              showDeleteConfirmation={showDeleteConfirmation}
              handleConfirmDeleteTag={handleConfirmDeleteTag}
              setShowDeleteConfirmation={setShowDeleteConfirmation}
              tagInput={tagInput}
              setTagInput={setTagInput}
              handleAddTag={handleAddTag}
            />
            <FilesInTag
              selectedTag={selectedTag}
              isFilesWithinTagsLoading={isFilesWithinTagsLoading}
              filesWithinTagsError={filesWithinTagsError}
              userFilesWithinTag={userFilesWithinTag}
              selectedFile={selectedFile}
              handleFileSelect={handleFileSelect}
            />
            <TagList
              isLoading={isLoading}
              error={error}
              userTags={userTags}
              selectedTag={selectedTag}
              handleTagSelect={handleTagSelect}
            />
          </div>
        )}

        {selectedIcon === 'customTools' && (
                   <div className="tags-section">
            <div className="custom-tools-title">
              <h2>Custom Tools</h2>
            </div>
            <CustomToolsList
              isLoading={isLoading}
              error={error}
              customTools={customTools}
              selectedTool={selectedTool}
              handleToolSelect={handleToolSelect}
            />

          </div>
        )}
      </div>

  </div>
);
};

export default UserFiles;