import React, { useState } from 'react';
import Login from './Login';
import { Link } from 'react-router-dom';
import Register from './Register';
import './auth.css';
import { useAuth } from './AuthContext';
import HelixLogo from '../../assets/Helix_logo-clear.png';
import { setLoginStatus } from '../../redux/actions/authActions';
import { useSelector, useDispatch } from 'react-redux';


const AuthPage = () => {
const dispatch = useDispatch();
  const isLogin = useSelector(state => state.auth.isLoggedIn);
  const { logout } = useAuth(); // Use the logout function from AuthContext
  return (
    <div className="auth-container">
      <div className="auth-container-right">
        <div className="auth-container-login-header">
          <div
            className={`login-title ${isLogin ? 'active-title' : 'inactive-title'}`}
            onClick={() => dispatch(setLoginStatus(true))}
          >
            <h1>Log in</h1>
          </div>
          <div
            className={`signup-title ${!isLogin ? 'active-title' : 'inactive-title'}`}
            onClick={() => dispatch(setLoginStatus(false))}
          >
            <h1>Sign up</h1>
          </div>
        </div>
        <div className="auth-container-center">
          {isLogin ? (
            <>
              <Login />
              <div
                className="switch-auth-method"
                onClick={() => dispatch(setLoginStatus(false))}
              >
                New to Helix Labs? Create a free account
              </div>
            </>
          ) : (
            <>
              <Register />
              <div
                className="switch-auth-method"
                onClick={() => dispatch(setLoginStatus(true))}
              >
                Already have an account? Log in
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AuthPage;