// Register.js
import React, { useState } from 'react';
import './login.css';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// New component for displaying messages
const Message = ({ message, type }) => (
  <div className={`message ${type}`}>
    {message}
  </div>
);

const Register = () => {
  const savedEmail = useSelector(state => state.email.email);
  const [email, setEmail] = useState(savedEmail);
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState(null);
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();
    setMessage(null);

    if (!email || !password) {
      setMessage({ text: "Both fields are required!", type: "error" });
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, password })
      });

      const data = await response.json();

      if (response.ok) {
        setMessage({ text: 'Registration successful! Redirecting to login...', type: "success" });
        setTimeout(() => navigate('/auth'), 2000);
      } else if (response.status === 409) {
        setMessage({ text: data.message, type: "info" });
        setTimeout(() => navigate('/auth'), 2000);
      } else {
        setMessage({ text: data.message || 'Registration failed', type: "error" });
      }

    } catch (error) {
      console.error('Registration error:', error);
      setMessage({ text: 'An error occurred. Please try again.', type: "error" });
    }
  };

  return (
    <div className="container">
      <h2>Register now for free</h2>
      {message && <Message message={message.text} type={message.type} />}
      <form className="form-login" onSubmit={handleRegister}>
        <input
          className="input-login-special-space"
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          className="input-login"
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button className="button-login" type="submit">
          Register
        </button>
      </form>
    </div>
  );
};

export default Register;