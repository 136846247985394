import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Pricing.css';
import { useState, useEffect } from 'react';
import {loadStripe} from '@stripe/stripe-js';
import { useAuth } from '../../Components/User_auth/AuthContext';
import Footer from '../../Components/Footer/Footer';




const Pricing = () => {
  const { isAuthenticated, token } = useAuth();
// Load Stripe and keep the instance in state for re-use
  const [stripePromise, setStripePromise] = useState(() => loadStripe(process.env.REACT_APP_STRIPE_KEY));
  const [activePlan, setActivePlan] = useState('individual');
  const [selectedPlan, setSelectedPlan] = useState(null);
  const navigate = useNavigate();

 const handleSubscriptionClick = async (planId) => {
  if (!isAuthenticated) {
    navigate('/auth');
  } else {
    let priceId;

    // Determine if the environment is development or production we made this to make it work for both prod and development
    //The isDevelopment is a boolean to determine if it is running locally or not. If it is running locally, it will use the development price ID, otherwise it will use the production price ID.
    const isDevelopment = process.env.NODE_ENV === 'development';

    // Select the price ID based on the environment and the selected plan
    if (planId === 'starter') {
      priceId = isDevelopment ? process.env.REACT_APP_STRIPE_PRICE_ID_STARTER_DEV : process.env.REACT_APP_STRIPE_PRICE_ID_STARTER;
    } else if (planId === 'pro') {
      priceId = isDevelopment ? process.env.REACT_APP_STRIPE_PRICE_ID_PRO_DEV : process.env.REACT_APP_STRIPE_PRICE_ID_PRO;
    } else if (planId === 'premium') {
      priceId = isDevelopment ? process.env.REACT_APP_STRIPE_PRICE_ID_PREMIUM_DEV : process.env.REACT_APP_STRIPE_PRICE_ID_PREMIUM;
    }
    console.log('Sending request with data:', { priceId, planId });
    console.log('Authorization Header:', `Bearer ${token}`);
   try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/create-checkout-session`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Correct
 // Send the auth token in the Authorization header
          },
          body: JSON.stringify({ priceId, planId }) // I just added the whole plan ID thing on Dec14th that is the only change
        });
        const { sessionId } = await response.json(); // The server responds with the session ID

        // Redirect to Stripe Checkout
        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({ sessionId });
        if (error) {
          console.error("Error redirecting to Stripe Checkout: ", error);
          // Show an error message to your user, handle the error, etc.
        }
     } catch (error) {
        console.error("Error creating checkout session: ", error);
        // Handle error here
    }
    }
  };
   const handlePricingTypeClick = type => {
        setActivePlan(type); // Update the active pricing type
    };
const getPricingTypeClassName = type => {
        return `pricing-type ${activePlan === type ? 'active' : ''}`;
    };
useEffect(() => {
    const planFromLocalStorage = localStorage.getItem('selectedPlan');
    setSelectedPlan(planFromLocalStorage);
  }, []);

  return (
  <div>
    <div className="pricing-container">
      <div className="top-pricing-container">
        <div className="top-pricing-intro-text">
          <h1>Choose the plan that works for you or your team</h1>
        </div>
        <div className="pricing-type-main">
          <div
            className={getPricingTypeClassName('individual')}
            onClick={() => handlePricingTypeClick('individual')}
          >
            <h3>Personal Plans</h3>
          </div>
          <div
            className={getPricingTypeClassName('enterprise')}
            onClick={() => handlePricingTypeClick('enterprise')}
          >
            <h3>Enterprise Plans</h3>
          </div>
        </div>
      </div>
      {activePlan !== 'enterprise' ? (
        <div className="pricing-card-container-main">
          <div className="all-3-cards">
            <div className="Starter">
              <div className="plan-title">
                <h2>Starter Plan</h2>
              </div>
              <div className="plan-price">
                <h3>$8.99 /month</h3>
              </div>
              <div className="plan-details">
                <h5>Includes:</h5>
                <h4>✔ 2,000 pages of document upload</h4>
                <h4>✔ 1,000 Helix chats per month</h4>
                <h4>✔ 150 Advanced chats per month</h4>
              </div>
              <div className="subscribe-container">
                <button className="subscribe-button" onClick={() => handleSubscriptionClick('starter')}>
                  Subscribe
                </button>
              </div>
            </div>
            <div className="Pro">
              <div className="plan-title">
                <h2>Pro Plan</h2>
              </div>
              <div className="plan-price">
                <h3>$15.99 /month</h3>
              </div>
              <div className="plan-details">
                <h5>Includes:</h5>
                <h4>✔ 20,000 pages of document upload</h4>
                <h4>✔ Unlimited Helix chats</h4>
                <h4>✔ 500 Advanced chats per month</h4>
              </div>
              <div className="subscribe-container">
                <button className="subscribe-button" onClick={() => handleSubscriptionClick('pro')}>
                  Subscribe
                </button>
              </div>
            </div>
            <div className="Premium">
              <div className="plan-title">
                <h2>Premium Plan</h2>
              </div>
              <div className="plan-price">
                <h3>$39.99 /month</h3>
              </div>
              <div className="plan-details">
                <h5>Includes:</h5>
                <h4>✔ 200,000 pages of document upload</h4>
                <h4>✔ Unlimited Helix chats</h4>
                <h4>✔ 2,000 Advanced chats per month</h4>
                <h4>✔ Guaranteed access to all upcoming features</h4>
              </div>
              <div className="subscribe-container">
                <button className="subscribe-button" onClick={() => handleSubscriptionClick('premium')}>
                  Subscribe
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="enterprise-plan-details">
      <div className="enterprise-cards">

        {/* Helix Enterprise Card */}
        <div className="enterprise-helix-card">
          <div className="enterprise-helix-custom-title">
            <h2>Helix Enterprise</h2>
          </div>

          <div className="enterprise-bullet-point">
          <ul>
            <li><strong>Enterprise Integration:</strong> Seamless integration with business systems.</li>
            <li><strong>Dedicated Support:</strong> Priority support from Helix experts.</li>
            <li><strong>Enhanced Security:</strong> Advanced protocols to safeguard data.</li>
            <li><strong>Scalable Solutions:</strong> Services grow with your company.</li>
            <li><strong>Preload Documents:</strong> Preload all your documents and add as you need.</li>
          </ul>
          </div>
          <div className="enterprise-pricing-text">
          <div className= "enterprise-pricing-text-outline">
          <p><strong>Pricing</strong>: Contact Helix.labs.ai@gmail.com </p>
            </div>
            </div>
        </div>

        {/* Custom Solutions Card */}
        <div className="enterprise-helix-card-custom">
          <div className="enterprise-helix-custom-title">
            <h2>Custom Solutions</h2>
          </div>
           <div className="enterprise-bullet-point">
          <ul>
            <li><strong>Custom AI Development:</strong> Personalized tools and more.</li>
            <li><strong>Consultation & Strategy:</strong> Ensure solutions align with goals.</li>
            <li><strong>Priority Support:</strong> Direct access to AI specialists.</li>
            <li><strong>Flexible Integration:</strong> Seamless integration with infrastructure.</li>
            <li><strong>Advanced Automation:</strong> Streamline operations and enhance efficiency.</li>
          </ul>
          </div>
          <div className= "enterprise-pricing-text">
          <div className= "enterprise-pricing-text-outline">
          <p><strong>Pricing </strong>: Contact Helix.labs.ai@gmail.com </p>
          <div>
          </div>
          </div>
          </div>
        </div>

      </div>
    </div>
      )}
    </div>
    <footer>
    <Footer />
    </footer>
    </div>
  );
};

export default Pricing;