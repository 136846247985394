import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../Components/User_auth/AuthContext';
import './Pricing.css';
import Footer from '../../Components/Footer/Footer';

const Success = () => {
  // Instantiate navigate
  const navigate = useNavigate();

  return (
  <div>
    <div className="success-container">
     <div className= "white-box">
      <div className="success-text">
        <h1>Payment Successful!</h1>
        <p>Thank you for your payment.
        Click below to use your new features! </p>
      </div>
      <div className="features-button-container">
        {/* Use navigate in the onClick handler */}
        <button className="features-button" onClick={() => navigate('/chat')}>Go to Chat</button>
      </div>
      </div>
    </div>
    <Footer />
    </div>
  );
};

export default Success;
