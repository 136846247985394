import React from 'react';
import '../ToolBar.css';

export const TagOptions = ({
  selectedTag,
  selectedFile,
  showDeleteConfirmation,
  handleConfirmDeleteTag,
  setShowDeleteConfirmation,
  tagInput,
  setTagInput,
  handleAddTag,
  handleChatClick
}) => {


const isFileSelected = selectedFile && selectedFile.file_name;

if (isFileSelected) {
    return (
      <div className="files-options-container-in-tags">
        <div className="file-options-top-container">
          <div className="options-container-title">Options for...</div>
        </div>
        <div className="selected-file-options-title">
          <span className="file-name">{selectedFile.file_name}</span>
        </div>
        {showDeleteConfirmation ? (
          <>
            <div className="confirm-button-container">
                <button
                  className="confirm-button"
                  type="button"
                  onClick={() => {
                  console.log('Selected file:', selectedFile);
                  console.log('Selected tag:', selectedTag);
                    if (selectedFile && (selectedFile.id || selectedFile.file_name) && selectedTag) {
                          handleConfirmDeleteTag(selectedFile.id || selectedFile.file_name, selectedTag);
                        } else {
                          console.error('Missing file identifier or tag name');
                  }
                }}
              >
                Confirm Remove Tag?
              </button>
            </div>
            <div className="cancel-button-container">
              <button className="cancel-button-delete" type="button" onClick={() => setShowDeleteConfirmation(false)}>
                Cancel
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="chatPDF-button-container">
              <button className="chatPDF-button" type="button" onClick={() =>  handleChatClick(selectedFile.file_name || selectedFile)}>
                Chat?
              </button>
            </div>
            <div className="delete-button-container">
              <button className="delete-button" type="button" onClick={() => setShowDeleteConfirmation(true)}>
                Delete Tag From File?
              </button>
            </div>
            <div className="add-tag-container">
              <input
                type="text"
                className="add-tag-input"
                value={tagInput}
                onChange={(e) => setTagInput(e.target.value)}
                placeholder="Enter tag"
              />
              <button className="add-tag-button" type="button" onClick={handleAddTag}>
                Add Tag
              </button>
            </div>
          </>
        )}
      </div>
    );
  }


  return (
    <div className="tag-options-container">
      <div className="tag-options-top-container">
        <div className="options-container-title">Options for...</div>
      </div>
      <div className="selected-tag-options-title">
        <span className="tag-name">
          {selectedTag ? selectedTag : 'No tag selected'}
        </span>
      </div>

      {showDeleteConfirmation ? (
        <>
          <div className="confirm-button-container">
            <button
              className="confirm-button"
              type="button"
              onClick={handleConfirmDeleteTag}
            >
              Confirm?
            </button>
          </div>
          <div className="cancel-button-container">
            <button
              className="cancel-button-delete"
              type="button"
              onClick={() => setShowDeleteConfirmation(false)}
            >
              Cancel
            </button>
          </div>
        </>
      ) : (
        <>

          <div className="add-tag-container">
            <input
              type="text"
              className="add-tag-input"
              value={tagInput}
              onChange={(e) => setTagInput(e.target.value)}
              placeholder="Enter tag"
            />
            <button className="add-tag-button" type="button" onClick={handleAddTag}>
              Add Tag
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default TagOptions;