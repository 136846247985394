import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../User_auth/AuthContext';

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();

  // Redirect if not authenticated; preserve the intended location
  if (!isAuthenticated) {
  console.log(isAuthenticated); // Add this in your ProtectedRoute to check the value

    return <Navigate to="/auth" state={{ from: location }} replace />;
  }

  return children;
};

export default ProtectedRoute;
