import React from 'react';
import './LandingPage.css';
import HeroSection from './HeroSection/HeroSection.jsx';
import HowTo from './HowTo/HowTo.jsx';
import FeaturesSection from './FeaturesSection/FeaturesSection.jsx';
import CallToAction from './CallToAction/CallToAction.jsx';
import EmailCaptureForm from './EmailCaptureForm/EmailCaptureForm.jsx';
import IconSection from './Icon/IconSection.jsx';
import BulletSection from './BulletSection/BulletSection.jsx';
import AboutUsSection from './AboutUsSection/AboutUsSection.jsx'
import Footer from '../Footer/Footer.jsx';
const LandingPage = () => {
  return (
  <div>
    <div className="landing-container">
      <HeroSection />
        <BulletSection />
        <AboutUsSection/>
      {/*<HowTo />*/}
      <EmailCaptureForm />
      <FeaturesSection />
      <IconSection />
      <CallToAction />

    </div>
          <Footer />
    </div>
  );
};

export default LandingPage;
