// src/components/Tooltip.js
import React from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

const Tooltip = ({ children, content }) => (
  <Tippy content={content} interactive={true}>
    {children}
  </Tippy>
);

export default Tooltip;
