import React from 'react';
import { Link } from 'react-router-dom';
import blogPosts from './blogData'; // assuming this is your array of blog posts
import './BlogList.css';
import Footer from '../../Components/Footer/Footer';

const BlogList = () => {
  return (
    <div>
      <div className="Blog-list-container">
        <div className="blog-list-title-color-bg">
        <div className="blog-list-title-section">
            <h1>Blog Posts</h1>
        </div>
        </div>
      <ul className="blog-list">
        {blogPosts.map(post => (
          <li key={post.id} className="blog-list-item">
            <span className="blog-date">{post.date}</span>
            <Link to={`/blog/${post.id}`} className="blog-title">{post.title}</Link>
            {/* Add the date and other details here */}
          </li>
        ))}
      </ul>
    </div>
    <Footer />
    </div>
  );
};

export default BlogList;
