
export const generateSummary = async (content, token) => {
  try {
    console.log("token Sent:", token);
    const response = await fetch('http://localhost:5000/generate_summary', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ Notes: content })
    });

    if (!response.ok) {
      console.error('Error generating summary:', response.statusText);
      return null;
    }

    const data = await response.json();
    return data.summary;

  } catch (error) {
    console.error('Error generating summary:', error);
    return null;
  }
};
