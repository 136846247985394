import React from 'react';
import { useAuth } from '../../../../Components/User_auth/AuthContext';
import axios from 'axios';
import '../ToolBar.css';


export const CustomToolList = ({ isLoading, error, customTools, selectedTool, handleToolSelect }) => {
  return (
    <div className="custom-tools-container">
      <h4>Custom Tools</h4>
      {isLoading ? (
        <p>Loading custom tools...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <div className="custom-tools">
          {customTools.length > 0 ? (
            customTools.map((toolName, index) => (
              <div
                key={index}
                className={`tool-container ${selectedTool === toolName ? 'selected' : ''}`}
                onClick={() => handleToolSelect(toolName)}
              >
                {toolName}
              </div>
            ))
          ) : (
            <p>No custom tools found.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default CustomToolList;