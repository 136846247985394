import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../Components/User_auth/AuthContext';
import './Account.css';
import Footer from '../../Components/Footer/Footer';
import axios from 'axios';


const ManageAccount = () => {
  // Instantiate navigate
  const [errorMessage, setErrorMessage] = useState('');
  const [accountInfo, setAccountInfo] = useState({ email: '', company: '', subscription: '' });// these are states used for the call to the backend to recognize what is sent.
  const { logout } = useAuth(); // Use the logout function from AuthContext
  const { token } = useAuth(); //da token
  const navigate = useNavigate(); // Hook to get the navigate function
  const [isCancelPopupVisible, setIsCancelPopupVisible] = useState(false);
  const [isMessagePopupVisible, setIsMessagePopupVisible] = useState(false);
  const [cancelMessage, setCancelMessage] = useState('');
  const [endDate, setEndDate] = useState(''); //this is the end date for their ssubscription (returned from cancel_subscription)

  const fetchAccountInfo = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/account_info`, {
        method: 'POST',
        headers: {
          // Include your JWT in the request headers
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setAccountInfo(data);
    } catch (error) {
      console.error('There was an error fetching the account info:', error);
    }
  };

  // Fetch account information on component mount meaning it will run once it loads
  useEffect(() => {
    fetchAccountInfo();
  }, []); // The empty array means this effect runs once on mount



  const handleCancelSubscription = async () => {
    setIsCancelPopupVisible(false);
  setErrorMessage('');

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/cancel-subscription`,
      null,
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

if (response.status === 200) {
    // Directly set the cancel message including the end date from the response
    const endDate = response.data.end_date; // Extract the end date from the response
    setCancelMessage(`Your subscription has been successfully canceled. You have until ${endDate} to utilize any premium features.`);
    setIsMessagePopupVisible(true); // Show success message popup
  } else {
    // Assuming the API response contains a meaningful error message
    setCancelMessage('Error canceling subscription. Please try again later.');
    setIsMessagePopupVisible(true); // Show error message popup
  }
} catch (error) {
  console.error('Error canceling subscription:', error);
  const errorMessage = error.response ? error.response.data.error : 'Error canceling subscription. Please try again later.';
  setCancelMessage(errorMessage);
  setIsMessagePopupVisible(true); // Show error message popup
}
};
const sendDailyQuestion = async () => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/send_llm_question`,
      null,
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    if (response.status === 200) {
      console.log("success");
    }
  } catch (e) {
    console.log("failed");
  }
};

  // Function to fetch account information

// this is the function which will be called when the user clicks on the change password button, simple route to forgot password
const handleRedirect = () => {
    navigate('/auth/forgotpassword');
  };
  // this is the function to handle the logout button
  const handleLogout = () => {
    logout(); // Call the logout function  };
  };




  return (
  <div>
    <div className="main-account-container">
      <div className="account-settings-title">
        <h1>Account Settings</h1>
      </div>
      <div className="settings-box-main">
        <div className="settings-box-title">
          <h2>Personal</h2>
        </div>
        <div className="settings-box-general">
            <div className="general-account-settings-box-main">
                <div className="general-account-settings-box-left">
                    <div className="general-account-settings-box-left-top">
                        <h3>Email</h3>
                    </div>
                    <div className="general-account-settings-box-left-bottom">
                        <p>{accountInfo.email}</p>
                    </div>
                </div>
                <div className="general-account-settings-box-right">

                </div>
            </div>

            <div className="general-account-settings-box-main">
                <div className="general-account-settings-box-left">
                    <div className="general-account-settings-box-left-top">
                        <h3>Subscription Type</h3>
                    </div>
                    <div className="general-account-settings-box-left-bottom">
                        <p>{accountInfo.subscription || 'None'}</p>
                    </div>
                </div>
                <div className="general-account-settings-box-right">
                  <h4><a href="/pricing">Upgrade</a></h4>
                </div>
            </div>

            <div className="general-account-settings-box-main-end">
                <div className="general-account-settings-box-left">
                    <div className="general-account-settings-box-left-top">
                        <h3>Company</h3>
                    </div>
                    <div className="general-account-settings-box-left-bottom">
                        <p>{accountInfo.company}</p>
                    </div>
                </div>
                <div className="general-account-settings-box-right">

                </div>
            </div>
        </div>
      </div>
      <div className="settings-box-main">
        <div className="settings-box-title">
          <h2>Account</h2>
        </div>
        <div className="settings-box-general">
            <div className="general-account-settings-box-main">
                <div className="general-account-settings-box-left">
                    <div className="general-account-settings-box-left-top">
                        <h3>Change Password</h3>
                    </div>
                    <div className="general-account-settings-box-left-bottom">
                    </div>
                </div>
                <div className="general-account-settings-box-right">
                    <button
                        className="general-account-settings-button"
                        onClick={handleRedirect}
                      >
                        Edit
                  </button>
                </div>
            </div>

            <div className="general-account-settings-box-main">
                <div className="general-account-settings-box-left">
                    <div className="general-account-settings-box-left-top">
                        <h3>Logout </h3>
                    </div>
                    <div className="general-account-settings-box-left-bottom">
                    </div>
                </div>
                <div className="general-account-settings-box-right">
                  <button
                        className="general-account-settings-button"
                        onClick={handleLogout}
                      >
                        Logout
                  </button>
                </div>
            </div>
            {/* Daily Question  */}
                <div className="general-account-settings-box-main">
                <div className="general-account-settings-box-left">
                    <div className="general-account-settings-box-left-top">
                        <h3>Daily Question </h3>
                    </div>
                    <div className="general-account-settings-box-left-bottom">
                    </div>
                </div>
                <div className="general-account-settings-box-right">
                  <button
                        className="general-account-settings-button"
                        onClick={sendDailyQuestion}
                      >
                        send Q
                  </button>
                </div>
            </div>
            {/* Daily Question  */}
            <div className="general-account-settings-box-main-end">
                <div className="general-account-settings-box-left">
                    <div className="general-account-settings-box-left-top">
                        <h3>Cancel Subscription</h3>
                    </div>
                    <div className="general-account-settings-box-left-bottom">

                    </div>
                </div>
                <div className="general-account-settings-box-right">
                    <button
                        className="general-account-settings-button"
                         onClick={() => setIsCancelPopupVisible(true)}
                         >
                        Cancel
                  </button>
                        {isCancelPopupVisible && (
                          <div className="popup-cancel-overlay">
                            <div className="popup-cancel-overlay-content">
                              <div className="popup-cancel-overlay-flex">
                                <div className="popup-cancel-overlay-title">
                                  <h2>We're sorry to see you go!</h2>
                                </div>
                                <div className="popup-cancel-overlay-paragraph-main">
                                  <div className="popup-cancel-overlay-paragraph">
                                    <p>Please note! Once subscription ends, any files exceeding the free storage limit will be permanently deleted. Additionally any premium features such as custom tools will be inaccessible.</p>
                                  </div>
                                </div>
                                <div className="popup-cancel-overlay-button-main">
                                  <div className="popup-cancel-overlay-button-section">
                                    <button className="popup-cancel-overlay-button-undo" onClick={() => setIsCancelPopupVisible(false)}>
                                      Undo
                                    </button>
                                    <button className="popup-cancel-overlay-button-confirm" onClick={handleCancelSubscription}>
                                      Cancel Subscription
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      {isMessagePopupVisible && (
                          <div className="popup-cancel-overlay">
                            <div className="popup-cancel-overlay-content">
                              <div className="popup-cancel-overlay-flex">
                                <div className="popup-cancel-overlay-title">
                                  <h2>Subscription Update</h2>
                                </div>
                                <div className="popup-cancel-overlay-paragraph-main">
                                  <div className="popup-cancel-overlay-paragraph">
                                    <p>{cancelMessage}</p>
                                  </div>
                                </div>
                                <div className="popup-cancel-overlay-button-main">
                                  <div className="popup-cancel-overlay-button-section-update">
                                    <button
                                      className="popup-cancel-overlay-button-undo"
                                      onClick={() => setIsMessagePopupVisible(false)}
                                    >
                                      Close
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                </div>
            </div>
        </div>
      </div>
    </div>
           <Footer />
    </div>
  );
};

export default ManageAccount;