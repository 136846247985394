// SherpaToolComponent.jsx
import React from 'react';
import ChatArea from '../../ChatArea';
import FileUploader from '../../Fileuploader';
import SherpaToolUserInput from '../../../UserInput/sherpaToolUserInput';

const SherpaToolComponent = ({
  isModalOpen,
  setModalOpen,
  handleFileData,
  setSelectedFileId,
  sessionId,
  selectedOption
}) => {
  return (
    <div className="helix-chatPage-sherpa" id="home">
      <div className="chatPage-topMessage-sherpa">
        <h3>Sherpa RX Beta Test</h3>
      </div>
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <FileUploader />
            <button className="close-modal-btn" onClick={() => setModalOpen(false)}>X</button>
          </div>
        </div>
      )}
      <ChatArea onFileSelect={handleFileData} setSelectedFileId={setSelectedFileId} />
      <div className="userInput-container">
        <SherpaToolUserInput
          sessionId={sessionId}
          openModal={() => setModalOpen(true)}
          selectedOption={selectedOption}
        />
      </div>
    </div>
  );
};

export default SherpaToolComponent;