// src/reducers/pdfBlobReducer.js

import { SET_BLOB_URL, CLEAR_BLOB_URL } from '../actions/PDFActions';

const initialState = {
  blobUrl: null,
};

const PDFReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BLOB_URL:
      return {
        ...state,
        blobUrl: action.payload,
      };
    case CLEAR_BLOB_URL:
      return {
        ...state,
        blobUrl: null,
      };
    default:
      return state;
  }
};

export default PDFReducer;
