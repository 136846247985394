import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../Components/User_auth/AuthContext';
import './Pricing.css';
import Footer from '../../Components/Footer/Footer';

const Cancel = () => {
  // Instantiate navigate
  const navigate = useNavigate();

  return (
   <div>
    <div className="cancel-container">
     <div className= "white-box">
      <div className="success-text">
        <h1>Your Transaction Has Been Canceled</h1>
        <p>Click below to return to the homepage </p>
      </div>
      <div className="features-button-container">
        {/* Use navigate in the onClick handler */}
        <button className="cancel-button" onClick={() => navigate('/')}>Home</button>
      </div>
      </div>
    </div>
    <Footer />
    </div>
  );
};

export default Cancel;
