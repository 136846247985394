import React from 'react';
import './HeroSection.css'; // import your stylesheet
import DNAVID from '../../../assets/Helix-Home.mp4';

const HeroSection = () => {
  return (
    <div className="hero-container">
      <div className="hero-image">
       <video autoPlay muted loop playsInline className='background-video'>
          <source src={DNAVID} type="video/mp4" />
        </video>
        <div className="video-overlay"></div>
         <div className="diagonal-overlay"></div>
        <div className="overlay-text">
          Welcome to Helix Labs
          <h2>Custom AI Solutions for Every Challenge</h2>
        </div>

      </div>
    </div>
  );
};

export default HeroSection;