import { useInView } from 'react-intersection-observer';
import './BulletSection.css';
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setEmail } from '../../../redux/actions/emailActions';
import { setLoginStatus } from '../../../redux/actions/authActions';
import { Link } from 'react-router-dom'; // Make sure to import Link

const BulletSection = () => {
  const [emailInput, setEmailInput] = useState('');
  const [activePhrase, setActivePhrase] = useState(0);
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });
  const servicesRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();


 const scrollToServices = () => {
    servicesRef.current.scrollIntoView({ behavior: 'smooth' });
  };

const handleChatButtonClick = () => {
  navigate('/chat');
};
const handleCustomAIButtonClick = () => {
  navigate('/custom-ai-solutions');
};
const handleAIConsultingButtonClick = () => {
  navigate('/ai-consulting');
};
const handleDataButtonClick = () => {
  navigate('/data-optimization');
};

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(setEmail(emailInput));
    dispatch(setLoginStatus(false));
    navigate('/auth');
  };

  const aiPhrases = [
    "Revolutionize",
    "Transform",
    "Optimize",
    "Innovate",
    "Empower"
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setActivePhrase((prev) => (prev + 1) % aiPhrases.length);
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div
      className={`minimalist-ai-container ${inView ? 'in-view' : ''}`}
      ref={ref}
    >
      <div className='ai-tagline-background'>
        <div className="ai-tagline">
          <h1>We help you</h1>
          <div className="rotating-phrase">
            {aiPhrases.map((phrase, index) => (
              <span key={index} className={index === activePhrase ? 'active' : ''}>
                {phrase}
              </span>
            ))}
          </div>
          <h1>with AI</h1>
        </div>
      </div> {/* Closing the 'ai-tagline-background' div */}

              <div className="top-bullet-container">
          <div className="top-bullet-container-left">
            <div className="top-bullet-container-title">
              <div className="top-bullet-container-left-box">
                <div className="new-tag">Custom</div>
                <h2>Helix Labs</h2>
                  <p>Powering Innovation with Custom AI Solutions</p>
                <div className="bottom-button-container">
                <button className="talk-to-claude-btn" onClick={scrollToServices}>See Solutions</button>
                </div>
              </div>
            </div>
          </div>
          <div className="top-bullet-container-right">
            <div className="top-bullet-container-title">
              <div className="bullet-email-container-bg">
                <div className="bullet-email-container">
                  <div className="new-tag">Individuals</div>
                  <h2>Helix AI</h2>
                  <p>Empowering Individuals With Helix AI</p>
                  <div className="bottom-button-container">
                    <button className="get-started-btn" onClick={handleChatButtonClick}>Talk to Helix</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      <div className="ai-services" ref={servicesRef}>
  {[
        {
      title: "Custom AI Tools",
      desc: "Tailored solutions for your unique challenges",
      icon: "🛠️",
      benefits: ["Increased efficiency", "Cost reduction", "Competitive advantage"],
      example: "Our AI data extraction tool saved researchers weeks of time.",
      route: "/custom-ai-solutions"
    },
    {
      title: "AI Consulting",
      desc: "Expert guidance to navigate the AI landscape",
      icon: "💡",
      benefits: ["Strategic AI roadmap", "Risk assessment", "Technology selection"],
      example: "We have helped software engineers increase their productivity by showing how to implement already available AI tools",
      route: "/ai-consulting"
    },
    {
      title: "Data Services",
      desc: "Harness the full potential of your data",
      icon: "📊",
      benefits: ["Enhanced data quality", "Actionable insights", "Predictive analytics"],
      example: "We use the latest AI tools to extract data and insights previously thought to be inaccessible",
      route: "/data-optimization"
    }
  ].map((service, index) => (
    <div key={index} className="service-column">
      <div className="service-icon">{service.icon}</div>
      <h2>{service.title}</h2>
      <p className="service-desc">{service.desc}</p>
      <ul className="service-benefits">
        {service.benefits.map((benefit, idx) => (
          <li key={idx}>{benefit}</li>
        ))}
      </ul>
      <div className="service-example">
        <h4>Success Story:</h4>
        <p>{service.example}</p>
      </div>
     <Link to={service.route} className="learn-more-btn">Learn More</Link>
    </div>
  ))}
</div>

      <div className="ai-footer">
        <p>Curious about AI potential? <a href="/contact">Let's talk</a></p>
      </div>
    </div>
  );
};

export default BulletSection;