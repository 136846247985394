import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import instagram from '../../assets/instagram.png';
import linkedin from '../../assets/linkedin.png';
import tiktok from '../../assets/tiktok.png';
import facebook from '../../assets/facebook.png';

const Footer = () => {

return (
    <footer>
        <div className="footer-main-container">
            <div className= "footer-upper-content">
                <div className="footer-helix-explanation">
                     <div className="footer-helix-explanation-text">
                         <h4> Helix Labs</h4>
                         <div className="footer-helix-explanation-paragraph">
                         <p>Transform How You Store and Access Your Knowledge</p>
                     </div>
                     </div>
                     <div className="footer-helix-explanation-try-button">
                            <Link to='/auth' className="try-helix-button">Try Helix for free</Link>
                     </div>
                </div>
                <div className="footer-link-section">
                    <div className="footer-link-subsections">
                        <h4>Use Cases</h4>
                        <p>Tutorial videos</p>
                        <p>Law</p>
                        <p>Engineering</p>
                        <p>Students</p>
                    </div>
                    <div className="footer-link-subsections">
                        <h4>Company</h4>
                        <p>About</p>
                        <p>Investors</p>
                        <p>Affiliate Program</p>
                    </div>
                    <div className="footer-link-subsections">
                        <h4>Legal</h4>
                        <p>Privacy Policy</p>
                        <p>Terms of Service</p>
                        <p>Refund Policy</p>
                    </div>
                </div>
            </div>
            <div className= "footer-lower-content">
                <div className="footer-lower-socials">
                      <a href="https://www.linkedin.com/company/helix-labs-ai" target="_blank" rel="noopener noreferrer">
                        <img src={linkedin} alt="LinkedIn" className="social-icons" />
                      </a>
                      <a href="https://www.instagram.com/helix.labs" target="_blank" rel="noopener noreferrer">
                        <img src={instagram} alt="Instagram" className="social-icons" />
                      </a>
                      <a href="https://www.tiktok.com/@helixlabsai" target="_blank" rel="noopener noreferrer">
                        <img src={tiktok} alt="TikTok" className="social-icons" />
                      </a>
                        <img src={facebook} alt="Facebook" className="social-icons" />
                 </div>
                 <div className="footer-lower-contact">
                    <p>For support and inquires email  Helix.labs.ai@gmail.com</p>
                </div >
            </div>
        </div>
    </footer>
)

};

  export default Footer;