import React, { useEffect, useRef, useState, useCallback } from 'react';
import ForceGraph3D from 'react-force-graph-3d';
import ControlPanel from './ControlPanel';
import Tooltip from './Tooltip';
import SearchBar from './SearchBar';
import './NetworkGraph.css';
import FileUploader from '../Chat/Fileuploader'


const NetworkGraph = () => {
  const fgRef = useRef();
  const [data, setData] = useState({ nodes: [], links: [] });
  const [filteredData, setFilteredData] = useState({ nodes: [], links: [] });
  const [selectedNode, setSelectedNode] = useState(null);
  const [highlightNodes, setHighlightNodes] = useState(new Set());
  const [highlightLinks, setHighlightLinks] = useState(new Set());
  const [hoverNode, setHoverNode] = useState(null);
  const [connectionMode, setConnectionMode] = useState(false);
  const [connectionStartNode, setConnectionStartNode] = useState(null);
  const [connectionFeedback, setConnectionFeedback] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    fetch('http://localhost:5000/api/network-data')
      .then(response => response.json())
      .then(data => {
        // Calculate the degree of each node
        const nodeDegrees = {};
        data.nodes.forEach(node => {
          nodeDegrees[node.id] = 0;
        });
        data.links.forEach(link => {
          nodeDegrees[link.source]++;
          nodeDegrees[link.target]++;
        });
        console.log('Node Degrees:', nodeDegrees); // Debugging log

        // Assign size based on degree
        const maxDegree = Math.max(...Object.values(nodeDegrees));
        data.nodes.forEach(node => {
          node.size = 5 + (nodeDegrees[node.id] / maxDegree) * 10;
          console.log(`Node ${node.id} size: ${node.size}`); // Adjust size scaling as needed
        });

        setData(data);
        setFilteredData(data); // Initialize filtered data

        // Print node IDs for debugging
        console.log('Node IDs:', data.nodes.map(node => node.id));
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  useEffect(() => {
    if (fgRef.current) {
      fgRef.current.d3Force('charge').strength(-120);
    }
  }, []);

  const handleNodeHover = (node) => {
    setHighlightNodes(new Set(node ? [node] : []));
    setHighlightLinks(new Set(
      node ? data.links.filter(link => link.source.id === node.id || link.target.id === node.id) : []
    ));
    setHoverNode(node || null);
  };

     const handleNodeClick = (node) => {
      if (connectionMode) {
        if (connectionStartNode && connectionStartNode.id !== node.id) {
          const newLink = { source: connectionStartNode, target: node };
          setData(prevData => ({
            ...prevData,
            links: [...prevData.links, newLink]
          }));
          setConnectionStartNode(null);
          setConnectionMode(false);
          setConnectionFeedback('');

          // Placeholder for backend code
          // fetch('http://localhost:5000/api/add-link', {
          //   method: 'POST',
          //   headers: { 'Content-Type': 'application/json' },
          //   body: JSON.stringify(newLink)
          // }).then(response => response.json())
          //   .then(result => console.log(result))
          //   .catch(error => console.error('Error adding link:', error));
        } else {
          setConnectionStartNode(node);
          setConnectionFeedback('Select another node to complete the connection');
        }
      } else {
        setSelectedNode(node);
      }
    };

  const handleFilterChange = (attribute) => {
    if (attribute) {
      setFilteredData({
        nodes: data.nodes.filter(node => node[attribute]),
        links: data.links.filter(link => data.nodes.some(node => node[attribute] && (node.id === link.source || node.id === link.target)))
      });
    } else {
      setFilteredData(data);
    }
  };

  const handleSearch = (query) => {
    console.log(`Searching for: ${query}`);
    setSearchQuery(query);
    if (query) {
      const regex = new RegExp(query, 'i'); // Case-insensitive search
      const matchedNodes = data.nodes.filter(node => regex.test(node.id.toString()));
      console.log('Matched Nodes:', matchedNodes);
      setHighlightNodes(new Set(matchedNodes));
      if (matchedNodes.length > 0) {
        setSelectedNode(matchedNodes[0]); // Show details of the first matched node
      }
    } else {
      setHighlightNodes(new Set());
      setSelectedNode(null);
    }
  };

const paintNode = useCallback((node, ctx) => {
  const size = 5 + node.size * 10;
  ctx.beginPath();
  ctx.arc(node.x, node.y, size, 0, 2 * Math.PI, false);

  // Determine fill color based on node state
  let fillColor;
  if (connectionMode && connectionStartNode && node.id === connectionStartNode.id) {
    fillColor = 'blue';  // Highlight the starting node in blue
  } else if (selectedNode && node.id === selectedNode.id) {
    fillColor = 'red';  // Highlight selected node in red
  } else if (highlightNodes.has(node)) {
    fillColor = 'yellow';  // Highlight hovered node in yellow
  } else {
    fillColor = node.color;  // Default color
  }

  ctx.fillStyle = fillColor;
  ctx.fill();

  // Add a border to the selected node
  if (selectedNode && node.id === selectedNode.id) {
    ctx.strokeStyle = 'white';
    ctx.lineWidth = 2;
    ctx.stroke();
  }
}, [selectedNode, highlightNodes, connectionMode, connectionStartNode]);
    const paintLink = (link, ctx) => {
      const gradient = ctx.createLinearGradient(link.source.x, link.source.y, link.target.x, link.target.y);
      gradient.addColorStop(0, highlightLinks.has(link) ? '#ffd700' : 'rgba(255,255,255,0.2)');
      gradient.addColorStop(1, highlightLinks.has(link) ? '#ffa500' : 'rgba(200,200,200,0.2)');

      ctx.strokeStyle = gradient;
      ctx.lineWidth = highlightLinks.has(link) ? 3 : 1.5;
      ctx.beginPath();
      ctx.moveTo(link.source.x, link.source.y);
      ctx.lineTo(link.target.x, link.target.y);
      ctx.stroke();
    };

    // Helper function to darken a color
    const darkenColor = (color, percent) => {
      const num = parseInt(color.replace("#",""), 16),
      amt = Math.round(2.55 * percent),
      R = (num >> 16) - amt,
      G = (num >> 8 & 0x00FF) - amt,
      B = (num & 0x0000FF) - amt;
      return "#" + (0x1000000 + (R<255?R<1?0:R:255)*0x10000 + (G<255?G<1?0:G:255)*0x100 + (B<255?B<1?0:B:255)).toString(16).slice(1);
    };

  return (
    <div className="graph-container">
      <button onClick={() => setIsModalOpen(true)}>Upload PDF</button>
      <ControlPanel
        attributes={['group', 'size']}
        onFilterChange={handleFilterChange}
      />
      <SearchBar onSearch={handleSearch} />
      {connectionFeedback && (
        <div className="connection-feedback">
          {connectionFeedback}
        </div>
      )}
      <ForceGraph3D
        ref={fgRef}
        graphData={filteredData}
        nodeAutoColorBy="group"
        nodeLabel={(node) => (
          <Tooltip content={`ID: ${node.id}, Group: ${node.group}, Size: ${node.size}`}>
            <span>Node: {node.id}</span>
          </Tooltip>
        )}
        onNodeClick={handleNodeClick}
        onNodeHover={handleNodeHover}
        linkDirectionalParticles={2}
        linkDirectionalParticleWidth={(link) => highlightLinks.has(link) ? 4 : 0}
        nodeCanvasObject={paintNode}
        linkCanvasObject={paintLink}
        key={connectionMode ? 'connecting' : 'normal'}
      />
      {selectedNode && (
        <div className="details-panel">
          <h2>Node Details</h2>
          <p><strong>ID:</strong> {selectedNode.id}</p>
          <p><strong>Group:</strong> {selectedNode.group}</p>
          <button onClick={() => {
            setConnectionMode(true);
            setConnectionStartNode(selectedNode);
            setConnectionFeedback('Select another node to complete the connection');
          }}>
            Add Connection
          </button>
          <button onClick={() => setSelectedNode(null)}>Close</button>
        </div>
      )}

      {/* Add the modal here, at the end of the graph-container div */}
      {isModalOpen && (
        <div className="modal-overlay-custom">
          <div className="custom-tool-info-container">
            <div className="custom-tool-info">
              <div className="custom-tool-info-title">
                <h2>How to Use Helix Advanced Document Reader</h2>
              </div>
              <div className="custom-tool-info-text">
                <p><strong>Upload Your Document:</strong> First upload the file you wish to analyze. This step is crucial to initiate your interactive session with the document.</p>
                <p><strong>Interactive Interface:</strong> Once uploaded, your document will appear on the left side of the screen, with a dedicated chat interface on the right. </p>
                <p><strong>Engage in Conversation:</strong> Utilize the chat feature to delve into your document. Our advanced tool analyzes the entire context of your PDF, finding connections in information which regular chats may overlook, allowing you to unearth highly specific information, catering to your precise queries. </p>
                <p><strong>Optimizing Your Experience:</strong> We're dedicated to providing you with accurate and comprehensive responses as quickly as possible. Please note, some queries may take longer to process due to their complexity. Rest assured, we're continuously working to enhance our technology for faster performance.</p>
              </div>
            </div>
          </div>
          <div className="modal-content-custom">
            <FileUploader />
            <button className="close-modal-btn" onClick={() => setIsModalOpen(false)}>X</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default NetworkGraph;