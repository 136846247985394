import { createStore, combineReducers, applyMiddleware } from 'redux';
import {thunk} from 'redux-thunk'; // Import Redux Thunk
import emailReducer from './reducers/emailReducer';
import authReducer from './reducers/authReducer';
import chatReducer from './reducers/chatReducer'; // Import your chatReducer
import toolActiveReducer from './reducers/toolActiveReducer';
import PDFReducer from './reducers/PDFReducer';
import sherpaToolActiveReducer from './reducers/sherpaToolActiveReducer';
// Combine reducers, now including the chatReducer
const rootReducer = combineReducers({
  email: emailReducer,
  auth: authReducer,
  chat: chatReducer, // Add the chatReducer here
  toolActive: toolActiveReducer,
  sherpaTool: sherpaToolActiveReducer,
  pdfBlob: PDFReducer,
});

// Create the store with the rootReducer and apply the thunk middleware
const store = createStore(
  rootReducer,
  applyMiddleware(thunk) // Apply Redux Thunk middleware
);

export default store;
