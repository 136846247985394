// SinglePDFLayout.js
import React from 'react';
import SliderToggle from '../ChatPageSlider/Slider';
import FileUploader from '../../Fileuploader';
import SinglePDFChatArea from '../../SinglePDFChatArea';
import SinglePDFUserInput from '../../../UserInput/SinglePDFUserInput';

const SinglePDFLayout = ({
  fileBlobUrl,
  isHelixSearchOn,
  setIsHelixSearchOn,
  isModalOpen,
  setModalOpen,
  sessionId,
  selectedOption,
  contextResponse,
  selectedFileId
}) => {
  return (
    <div className="parent-container">
      <div className="left-content">
        <div className="chat-PDF-container">
          {fileBlobUrl && (
            <iframe src={fileBlobUrl} style={{ width: '100%', height: '100%' }} />
          )}
        </div>
      </div>
      <div className="right-content">
        <div className="helix-chatPage-split" id="home">
          <div className="chatPage-topMessage">
            <SliderToggle
              isHelixSearchOn={isHelixSearchOn}
              setIsHelixSearchOn={setIsHelixSearchOn}
            />
          </div>
          {isModalOpen && (
            <div className="modal-overlay">
              <div className="modal-content">
                <FileUploader />
                <button className="close-modal-btn" onClick={() => setModalOpen(false)}>X</button>
              </div>
            </div>
          )}
          <SinglePDFChatArea />
          <div className="userInput-container">
            <SinglePDFUserInput
              sessionId={sessionId}
              openModal={() => setModalOpen(true)}
              selectedOption={selectedOption}
              contextResponse={contextResponse}
              selectedFileId={selectedFileId}
              isHelixSearchOn={isHelixSearchOn}
              setIsHelixSearchOn={setIsHelixSearchOn}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SinglePDFLayout;