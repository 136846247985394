import React, { useState,  useEffect, useCallback } from 'react';
import './AboutUsSection.css';

const AboutUsSection = () => {
 const [activeLayer, setActiveLayer] = useState(0);
  const [isLocked, setIsLocked] = useState(false);
  const [lastInteraction, setLastInteraction] = useState(Date.now());

const layers = [
   {
       title: "Custom AI Solutions",
          content: "Tailored AI tools to solve complex business challenges.",
          icon: "🧠",
          details: "Our AI solutions are meticulously crafted to address each client's unique needs. We harness the power of cutting-edge Large Language Models (LLMs), advanced machine learning techniques, and state-of-the-art vision models to create robust tools that drive your business's growth and productivity. Our team  works closely with you to develop AI solutions that integrate seamlessly with your existing processes and deliver tangible results.",
          subPoints: [
            "Large Language Model Integration",
            "Predictive Analytics Tools",
            "AI-Powered Automation Solutions",
          ],
          benefits: [
            "Enhance decision-making with AI-driven insights",
            "Gain competitive advantage with cutting-edge AI technology",
            "Improve customer experiences with personalized AI interactions",
            "Unlock new business opportunities with AI-powered innovation",
          ],

          },
    {
         title: "Data Analytics & Data Science",
            content: "Unlock the Power of Your Data",
            icon: "📊",
            details: "We transform raw information into strategic insights, driving your business forward. Our services include predictive analytics, operational optimization, customer insights, risk management, and data visualization.",
            subPoints: [
              "Transform complex datasets into clear, actionable visualizations",
              "Identify inefficiencies and streamline processes",
              "Understand your audience and enhance engagement",

            ],
            approach: [
              "Tailored Solutions: Customized to your specific challenges",
              "Cutting-Edge Technology: Leveraging AI and machine learning",
            ],
            whyChooseUs: [
              "Expert team of data scientists and engineers",
              "End-to-End Support from data collection to implementation",
              "ROI Focused initiatives for measurable business impact",
            ],
          },
    {
     title: "Knowledge Base Creation",
          content: "Transform your documents into an intelligent, queryable knowledge base.",
          icon: "☁️",
          details: "Helix AI leverages cutting-edge Large Language Models to create a powerful, interactive knowledge base from your documents. This system allows you to effortlessly upload, organize, and query your information, transforming static data into dynamic, accessible intelligence.",
          subPoints: [
            "Seamless Document Integration",
            "Natural Language Querying",
            "Contextual Understanding",
          ],
          benefits: [
            "Instant access to critical information",
            "Reduce time spent searching through documents",
            "Improve decision-making with comprehensive insights",
          ],
    },
    {
     title: "AI Consulting",
      content: "Expert guidance on AI strategy and implementation.",
      icon: "💡",
      details: "Our consulting services help businesses navigate the complex world of AI. We provide strategic advice on AI adoption, conduct feasibility studies, and offer technical expertise to ensure successful AI integration into existing business processes. Our team of experts works closely with you to develop a tailored AI roadmap that aligns with your business goals and maximizes ROI.",
      subPoints: [
        "AI Readiness Assessment",
        "Strategic AI Roadmapping",
        "Technology Selection",
        "Implementation Planning",
      ],
      benefits: [
        "Accelerate AI adoption and digital transformation",
        "Stay ahead of the competition with cutting-edge AI solutions",
      ],
     },
  ];
  const switchLayer = useCallback(() => {
    if (!isLocked) {
      setActiveLayer((prevLayer) => (prevLayer + 1) % layers.length);
    }
  }, [isLocked, layers.length]);

  const handleClick = (index) => {
    setActiveLayer(index);
    setIsLocked(true);
    setLastInteraction(Date.now());
  };

  useEffect(() => {
    const intervalId = setInterval(switchLayer, 3000);
    return () => clearInterval(intervalId);
  }, [switchLayer]);

  useEffect(() => {
    const unlockTimer = setInterval(() => {
      if (isLocked && Date.now() - lastInteraction > 16000) {
        setIsLocked(false);
      }
    }, 1000);
    return () => clearInterval(unlockTimer);
  }, [isLocked, lastInteraction]);




return (
  <section className="about-us-section">
    <h2 className="section-title">What is Helix Labs</h2>

    <div className="about-us-main">
      <div className="ai-layers">
        {layers.map((layer, index) => (
          <div
            key={index}
            className={`ai-layer ${index === activeLayer ? 'active' : ''}`}
            onClick={() => handleClick(index)}
          >
            <div className="layer-icon">{layer.icon}</div>
            <div className="layer-content">
              <h3>{layer.title}</h3>
              <p>{layer.content}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="about-us-detail-right">
        <h3>{layers[activeLayer].title}</h3>
        <p>{layers[activeLayer].details}</p>

        {layers[activeLayer].subPoints && (
          <div className="sub-points">
            <h4>Key Features:</h4>
            <ul>
              {layers[activeLayer].subPoints.map((point, index) => (
                <li key={index}>{point}</li>
              ))}
            </ul>
          </div>
        )}

        {layers[activeLayer].benefits && (
          <div className="benefits">
            <h4>Benefits:</h4>
            <ul>
              {layers[activeLayer].benefits.map((benefit, index) => (
                <li key={index}>{benefit}</li>
              ))}
            </ul>
          </div>
        )}

        {layers[activeLayer].useCases && (
          <div className="use-cases">
            <h4>Use Cases:</h4>
            <ul>
              {layers[activeLayer].useCases.map((useCase, index) => (
                <li key={index}>{useCase}</li>
              ))}
            </ul>
          </div>
        )}

        {layers[activeLayer].expertise && (
          <div className="expertise">
            <h4>Our Expertise:</h4>
            <ul>
              {layers[activeLayer].expertise.map((area, index) => (
                <li key={index}>{area}</li>
              ))}
            </ul>
          </div>
        )}

        {layers[activeLayer].callToAction && (
          <p className="call-to-action">{layers[activeLayer].callToAction}</p>
        )}
      </div>
    </div>

    <div className="company-impact">
      <h3>Our Vision for Impact</h3>
      <div className="impact-stats">
        <div className="stat">
          <span className="stat-number">100%</span>
          <span className="stat-label">Committed to Client Success</span>
        </div>
        <div className="stat">
          <span className="stat-number">10+</span>
          <span className="stat-label">Industries We Aim to Serve</span>
        </div>
        <div className="stat">
         <span className="stat-number">80%</span>
         <span className="stat-label">Potential Time Saved with AI</span>
        </div>
      </div>
    </div>
  </section>
);
};

export default AboutUsSection;