import React, { useState, useEffect } from 'react';
import ChatArea from '../../ChatArea';
import './ChatType.css';

const ChatTypeDropdown = ({ selectedOption, setSelectedOption }) => {
  const [isOpen, setIsOpen] = useState(false);
  const options = ['Helix', 'Helix Advanced'];
   const optionDescriptions= {
   'Helix': 'Classic Helix responses, chat with your documents, discover information.',
    'Helix Advanced': 'Advanced Responses, still under development. Chat responses may take as long as 40 seconds to post.'
   }
  const toggleDropdown = () => setIsOpen(!isOpen);

  const selectOption = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };
  //const availableOptions = options.filter(option => option !== selectedOption);

  return (
    <div className="chat-type-container">
      <div className="chat-type-header" onClick={toggleDropdown}>
        {selectedOption} <span className={`arrow ${isOpen ? 'open' : ''}`}></span>
      </div>
      {isOpen && (
        <div className="dropdown-list">
          {options.map((option, index) => (
            <div
              key={index}
              className="dropdown-item"
              onClick={() => selectOption(option)}
            >
              <div className= "option-label">{option}</div>
              <div className =" option-description">{optionDescriptions[option]}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ChatTypeDropdown;
