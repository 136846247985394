import React, { useState } from 'react';
import axios from 'axios';
import './AIConsulting.css';  // We'll create this CSS file next
import Footer from '../../../Components/Footer/Footer';


const AIConsulting = () => {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const SuccessMessage = () => (
  <div className="success-message">
    <p>Thank you! Your message has been sent successfully. We'll be in touch soon.</p>
  </div>
);

    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/consult`, formData);
        setFormData({ name: '', email: '', message: '' });
        setShowSuccessMessage(true);
        setTimeout(() => setShowSuccessMessage(false), 5000); // Hide the message after 5 seconds
      } catch (error) {
        alert('An error occurred. Please try again.');
      }
    };

return (
      <div>
        <div className="ai-consulting">
          <section className="hero">
            <h1>Navigate the AI Landscape with Confidence</h1>
            <p>Expert guidance to help your business harness the power of AI</p>
          </section>

          <section className="intro">
            <h2>AI Consulting Services</h2>
            <p>We help businesses understand, implement, and benefit from AI technologies, ensuring you stay ahead in the rapidly evolving digital landscape.</p>
          </section>

          <section className="consulting-services">
            <h2>Our Consulting Services</h2>
            <div className="service-grid">
              <div className="service-item">
                <h3>AI Strategy Development</h3>
                <p>Crafting tailored AI strategies aligned with your business goals</p>
              </div>
              <div className="service-item">
                <h3>AI Readiness Assessment</h3>
                <p>Evaluating your organization's capacity to adopt and benefit from AI</p>
              </div>
              <div className="service-item">
                <h3>AI Implementation Planning</h3>
                <p>Detailed roadmaps for integrating AI into your existing processes</p>
              </div>
              <div className="service-item">
                <h3>AI Ethics and Governance</h3>
                <p>Ensuring responsible and ethical use of AI in your organization</p>
              </div>
            </div>
          </section>

          {/* Replace the existing consulting-cta div with this new contact section */}
          <div className="contact">
            <div className="contact-content">
              <h2>Ready to Transform Your Business?</h2>
              <p>Take the first step towards AI-driven success. Schedule a consultation with our experts today.</p>
              <form className="contact-form" onSubmit={handleSubmit}>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Your Name"
                  required
                />
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Your Email"
                  required
                />
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  placeholder="Tell us about your AI consultation needs"
                  required
                ></textarea>
                <button type="submit">Schedule a Consultation</button>
              </form>
              {showSuccessMessage && <SuccessMessage />}
            </div>
          </div>
        </div>
        <Footer/>
      </div>
    );
};

export default AIConsulting;