// src/store/reducers/authReducer.js
//This reducer is used to update the state of the which form is being shown on the auth page. Login or register.
const initialState = {
  isLoggedIn: false,
};

function authReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_LOGIN_STATUS':
      return {
        ...state,
        isLoggedIn: action.payload,
      };
    default:
      return state;
  }
}

export default authReducer;
