import React, { useState } from 'react';
import './Slider.css'; // Make sure to create this CSS file

const SliderToggle = ({isHelixSearchOn, setIsHelixSearchOn}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  //this function toggles the state of isHelixSearchOn this will be used to change the backend connection and send it
  //to a different chat route to then not use vectors and rather just general AI chat
  const toggleHelixSearch = () => {
    setIsHelixSearchOn(!isHelixSearchOn);
  };

return (
    <div className="slider-container">
  <div className={`slider ${isHelixSearchOn ? 'on' : 'off'}`} onClick={toggleHelixSearch}>
    <div className="slider-circle"></div>
  </div>
  <span
    className="tooltip-target underlined-text" // Added the class here
    onMouseEnter={() => setShowTooltip(true)}
    onMouseLeave={() => setShowTooltip(false)}
  >
    {isHelixSearchOn ? 'Helix search on' : 'Helix search off'}
    {showTooltip && (
      <div className="tooltip">
        <p>This toggle allows you to choose whether Helix uses your document for answers.</p>
        <p><strong>When On:</strong> Helix will provide answers using your document as context.</p>
        <p><strong>When Off:</strong> Helix will no longer use the document as context, allowing you to have conversations outside the context of the document, such as getting the definition of a word or explaining a difficult to understand topic.</p>
      </div>
    )}
  </span>
</div>

  );
};

export default SliderToggle;

