import React, { useState, useRef } from "react";
import { Link } from 'react-router-dom';
import axios from "axios";
import "./Chat.css";
import { useAuth } from '../../Components/User_auth/AuthContext';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setToolSessionID } from '../../redux/actions/toolActions';
import {setBlobUrl } from '../../redux/actions/PDFActions';

function FileUploader() {
  const [isUploaded, setIsUploaded] = useState(false); // State to hold the upload status
  const [isUploading, setIsUploading] = useState(false); // State to hold the upload status FOR DISABLING BUTTON of upload and file selection
  const [inputKey, setInputKey] = useState(Date.now());//used to reset the input
  const [pageLimit, setPageLimit] = useState(false);// NOT USED YET
  const { token } = useAuth(); //token for authorization
  const [errorMessage, setErrorMessage] = useState(""); //displays error messages
  const [selectedFile, setSelectedFile] = useState(null); // State to hold the selected file, gets used for the name
  const [fileName, setFileName] = useState("No file chosen, drag or select file"); // State to hold the file name
  const fileInputRef = useRef(null); // Ref to the hidden file input
  const [displayMessage, setDisplayMessage] = useState(""); //displays message after upload
  const [upgradeDisplay, setUpgradeDisplay] = useState(false); // set to false initially because only when the pageLimit is reached is when it shows up!
  const isToolActive= useSelector((state) => state.toolActive.isToolActive); //checks if the tool is active
  const dispatch = useDispatch(); //dispatches the action
  const toolSessionID = useSelector((state) => state.toolActive.toolSessionID); //checks the sessionID



    // Function to handle the file input changes, the event is the parameter that represents the users interaction (file is selected)
  const handleFileChange = (event) => {
    const file = event.target.files[0]; //this access the first file in the array (files are stored in arrays in react [0] is first
    //this ensures the user has selected a file if not then the array is null
    if (file && isValidFileType(file.type)) {
     setSelectedFile(file);
     setFileName(file.name); // Update the file name state
     setErrorMessage(""); // Clear error message
   } else if (!file) {
     setFileName("No file chosen"); // Reset the file name display
   } else {
     setErrorMessage("File must be a PDF, JPG, JPEG, or PNG!"); // Display error message
     setFileName("No file chosen"); // Reset the file name display
     setSelectedFile(null); // Clear the selected file
   }
 };
 // Helper function to check if the file type is valid
 const isValidFileType = (fileType) => {
   const allowedTypes = ["application/pdf", "image/jpeg", "image/png", "image/jpg"];
   return allowedTypes.includes(fileType);
 };

    //this function is called when the user clicks the custom button this is because the basic fileuploader is ugly and so to get around it we have this function
    //which pretty much just clicks the OG fileuploader invisibly
    const handleCustomButtonClick = () => {
        // Trigger the hidden file input click event
        fileInputRef.current.click();
        setErrorMessage(""); // Clear error message
      };


  const handleUpload = async () => {
      setIsUploading(true); //start uploading
      const formData = new FormData();
      formData.append("file", selectedFile);//adds the selected file to this FormData. The first argument "file" is the key,
      //and selectedFile (the file chosen by the user) is the value.


      const url = `${process.env.REACT_APP_API_URL}/upload`;
     try {
        const response = await axios.post(url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`
          },
        });
       //the status 200 is for success it then sets the isUploaded state to true which changes the color of the button to green
        if (response.status === 200) {

      setIsUploaded(true);
      // Clear the input for a new file
      setInputKey(Date.now());
      // Here you might want to use a longer delay before resetting the isUploaded state
      setTimeout(() => {
        setIsUploaded(false); // Remove the green color
        setDisplayMessage("Successful Upload!"); // Display the success message

       setTimeout(() => {
          setDisplayMessage(""); // Clear the display message
          setFileName("No file chosen, drag or select file"); // Reset the file name display
      }, 4000); // 3 seconds for better visibility
       }, 1000); // Adjust this time based on how long you show the green success indication
    } else { // Handle if not a none 200 status (AKA not success responses)
          if (response.data && response.data.error) {
            setErrorMessage(response.data.error);
          } else {
            setErrorMessage('Failed to upload file. This file might be a scanned pdf or encrypted.');
          }
      // Handle other statuses or add user feedback accordingly
    }
  } catch (error) {
    if (error.response) {
    // The request was made and the server responded with a status code that falls out of the range of 2xx
        if (error.response.status === 400 && error.response.data.status==='Page limit exceeded.'){
         setErrorMessage("Upload can not be completed due to page limit being reached!"); // Set the specific error message from the backend
         setFileName("No file chosen, drag or select file"); //reset the file name display
         setPageLimit(true); // Set the page limit state to true (used to display upgrade box to the user
         setUpgradeDisplay(true); // Set the upgrade display state to true (used to display upgrade box to the user
        }else if (error.response && error.response.status === 413) {
        //THIS NEEDS TO BE TESTED ONLY CAN TEST WHEN ERROR IS IN AWS AND FROM NGINX LIMIT, it currently works and is set to 200mb
            setErrorMessage("File size too large!"); // Display error message
        }
        else{
         setErrorMessage(error.response.data.message || "Error uploading file");
        }

    } else {
        setErrorMessage("An error occurred while uploading the file scanned documents or encrypted documents are not supported currently ");
    }
    // Provide error feedback to the user
  } finally {
    setIsUploading(false); // Reset the isUploading state allowing button to be used again
  }
    };
    ////////////////////////////////
//CUSTOM TOOL UPLOAD FUNCTION
const handleUploadCustomTools = async () => {
      setIsUploading(true); //start uploading
      const formData = new FormData();
      formData.append("file", selectedFile);//adds the selected file to this FormData. The first argument "file" is the key,
      //and selectedFile (the file chosen by the user) is the value.

      const url = `${process.env.REACT_APP_API_URL}/helix-upload`;
     try {
        const response = await axios.post(url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`
          },
        });
       //the status 200 is for success it then sets the isUploaded state to true which changes the color of the button to green
        if (response.status === 200) {
      const blobUrl = URL.createObjectURL(selectedFile);
      dispatch(setBlobUrl(blobUrl)); // Update the global state with the new blob URL
      setIsUploaded(true);
      // Clear the input for a new file
      setInputKey(Date.now());
      // Here you might want to use a longer delay before resetting the isUploaded state
      setTimeout(() => {
        setIsUploaded(false); // Remove the green color
        setDisplayMessage("Successful Upload!"); // Display the success message
         if (response.data && response.data.session_id) {
        //setting tool session id
        dispatch(setToolSessionID(response.data.session_id))
        console.log(response.data.session_id, 'poop');
      }
       setTimeout(() => {
          setDisplayMessage(""); // Clear the display message
          setFileName("No file chosen, drag or select file"); // Reset the file name display
      }, 4000); // 3 seconds for better visibility
       }, 1000); // Adjust this time based on how long you show the green success indication
    } else { // Handle if not a none 200 status (AKA not success responses)
          if (response.data && response.data.error) {
            setErrorMessage(response.data.error);
          } else {
            setErrorMessage('Failed to upload file. This file might be a scanned pdf or encrypted.');
          }
      // Handle other statuses or add user feedback accordingly
    }
  } catch (error) {
    if (error.response) {
    // The request was made and the server responded with a status code that falls out of the range of 2xx
        if (error.response.status === 400 && error.response.data.status==='Page limit exceeded.'){
         setErrorMessage("Upload can not be completed due to page limit being reached!"); // Set the specific error message from the backend
         setFileName("No file chosen, drag or select file"); //reset the file name display
         setPageLimit(true); // Set the page limit state to true (used to display upgrade box to the user
         setUpgradeDisplay(true); // Set the upgrade display state to true (used to display upgrade box to the user
        }else if (error.response && error.response.status === 413) {
        //THIS NEEDS TO BE TESTED ONLY CAN TEST WHEN ERROR IS IN AWS AND FROM NGINX LIMIT, it currently works and is set to 200mb
            setErrorMessage("File size too large!"); // Display error message
        }
        else{
         setErrorMessage(error.response.data.message || "Error uploading file");
        }

    } else {
        setErrorMessage("An error occurred while uploading the file scanned documents or encrypted documents are not supported currently ");
    }
    // Provide error feedback to the user
  } finally {
    setIsUploading(false); // Reset the isUploading state allowing button to be used again
  }
    };


    //this is the main function to choose your endPoint
    const handleUploadButtonClicked = () => {
    if (isToolActive){
            handleUploadCustomTools();
        }else{
            handleUpload();
        }
    }

  const handleDrop = (event) => {
    event.preventDefault(); // Prevent the file from being opened
    const file = event.dataTransfer.files[0];
    if (file && isValidFileType(file.type)) {
      setSelectedFile(file);
      setFileName(file.name);
      setErrorMessage(""); // Clear any previous error messages
    } else {
      setErrorMessage("File must be a PDF, JPG, JPEG, or PNG!");
    }
  };
   const handleDragOver = (event) => {
    event.preventDefault(); // Prevent default behavior
    event.stopPropagation(); // Stop propagation to lower elements
  };

// JSX
return (
  <div className={`file-uploader ${isUploaded ? "successful-upload" : ""}`}>

    {/* Flex container for buttons and file name */}
      <div className="file-upload-container" onDragOver={handleDragOver} onDrop={handleDrop}>

      {/* Custom file button */}
      <button
        type="button"
        className="custom-file-button"
        onClick={handleCustomButtonClick}
      >
        Choose File
      </button>

      {/* File name display */}
      <input
        type="text"
        className="file-name-display"
        value={displayMessage || fileName}
        readOnly
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      />

      {/* Upload button */}
        <button
          type="button"
          className="uploader-button"
          onClick={handleUploadButtonClicked}
          disabled={isUploading || !selectedFile} // Disable button when isUploading is true
        >
          {isUploading ? 'Uploading...' : 'Upload'} {/* Change text based on isUploading */}
        </button>


    </div>

    {/* Hidden file input */}
    <input
      key={inputKey}
      type="file"
      ref={fileInputRef}
      onChange={handleFileChange}
      disabled={isUploading}
      style={{ display: 'none' }} // Hide the actual file input
    />
    <div>
      {/* ... other component elements ... */}
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      {/* ... other component elements ... */}
    </div>
    {/* Conditional rendering of the upgrade message */}
      {upgradeDisplay && (
        <div className="upgrade">
            <p><Link to="/pricing">Click here to upgrade your account!</Link></p>
        </div>
      )}
  </div>
);

}
export default FileUploader;

