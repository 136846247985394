// src/actions/pdfBlobActions.js

// Action Types
export const SET_BLOB_URL = 'SET_BLOB_URL';
export const CLEAR_BLOB_URL = 'CLEAR_BLOB_URL';

// Action Creators
export const setBlobUrl = (url) => ({
  type: SET_BLOB_URL,
  payload: url,
});

export const clearBlobUrl = () => ({
  type: CLEAR_BLOB_URL,
});
