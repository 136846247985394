// SherpaToolReducer
const initialState = {
  isActive: false,
  sessionID: null,
};

const sherpaToolReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SHERPA_TOOL_ACTIVE':
      return {
        ...state,
        isActive: true,
      };
    case 'SET_SHERPA_TOOL_INACTIVE':
      return {
        ...state,
        isActive: false,
      };
    case 'SET_SHERPA_TOOL_SESSION_ID':
      return {
        ...state,
        sessionID: action.sessionID,
      };
    default:
      return state;
  }
};

export default sherpaToolReducer;