import React, { useState, useEffect } from 'react';
import { useAuth } from '../../Components/User_auth/AuthContext';

function NotePicker({ onNoteSelected }) {
    const { isAuthenticated, token } = useAuth();
    const [notes, setNotes] = useState([]);
    const [selectedNoteId, setSelectedNoteId] = useState(null);

    useEffect(() => {
        // Fetch the list of saved notes from the backend when the component mounts
        async function fetchNotes() {
        const response = await fetch('http://localhost:5000/api/notes', {
             headers: {
                'Authorization': `Bearer ${token}`
        }
    });

            const data = await response.json();
            setNotes(data.notes);
        }
        fetchNotes();
    }, []);

    return (
        <div className="note-picker">
            <select
                value={selectedNoteId}
                onChange={e => setSelectedNoteId(e.target.value)}
            >
                <option value="">Select a note</option>
                {notes.map(note => (
                    <option key={note.id} value={note.id}>{note.title}</option>
                ))}
            </select>
            <button onClick={() => onNoteSelected(selectedNoteId)}>Open Note</button>
        </div>
    );
}

export default NotePicker;
