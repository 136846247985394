import React, { useState, useEffect } from 'react';
import { FaLink } from 'react-icons/fa';

import ReactMde, { getDefaultCommandMap, getDefaultToolbarCommands } from 'react-mde';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import 'react-mde/lib/styles/css/react-mde-all.css';
import './NoteEditor.css';
import { generateSummary } from './NoteActions';
import { useAuth } from '../../Components/User_auth/AuthContext';
import NotePicker from './NotePicker.jsx';
import FileSelector from './FileSelector.jsx';
import NoteLinkModal from './NoteLinkModal.jsx';  // Import the modal component

function NoteEditor({ onSave }) {
  const { isAuthenticated, token } = useAuth();
  const [content, setContent] = useState('');
  const [keywords, setKeywords] = useState('');
  const [selectedNoteId, setSelectedNoteId] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedTab, setSelectedTab] = useState('write');
  const [linkedNotes, setLinkedNotes] = useState([]);
  const [isLinkModalOpen, setIsLinkModalOpen] = useState(false);  // State to control modal visibility

  useEffect(() => {
    async function loadSelectedNote() {
      if (selectedNoteId) {
        try {
          const response = await fetch(`http://localhost:5000/api/notes/${selectedNoteId}`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          const data = await response.json();
          if (response.ok) {
            setContent(data.note.title + "\n\n" + data.note.content); // Include title in content
            setKeywords(data.note.keywords.join(', '));
            setLinkedNotes(data.note.links);
          } else {
            console.error("Error loading note:", data.error);
          }
        } catch (error) {
          console.error("Error loading note:", error);
        }
      }
    }
    loadSelectedNote();
  }, [selectedNoteId, token]);

  const handleGenerateSummary = async () => {
    const generatedSummary = await generateSummary(content, token);
    if (generatedSummary) {
      setContent(content + "\n\nGenerated Summary:\n" + generatedSummary);
    }
  };

  const handleFileSelect = (file) => {
    setSelectedFile(file);
  };

  const handleTranscribe = async () => {
    if (!selectedFile) {
      console.error("No file selected for transcription");
      return;
    }
    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      const response = await fetch('http://localhost:5000/api/transcribe', {
        method: 'POST',
        body: formData,
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      const data = await response.json();
      if (response.ok) {
        setContent(data.content);
      } else {
        console.error("Error transcribing note:", data.error);
      }
    } catch (error) {
      console.error("Error transcribing note:", error);
    }
  };

  const handleSave = async () => {
    const lines = content.split('\n');
    const title = lines[0];
    const noteContent = lines.slice(1).join('\n');

    const noteData = {
      title,
      content: noteContent,
      keywords: keywords.split(',').map(keyword => keyword.trim())
    };
    const url = selectedNoteId ? `http://localhost:5000/api/notes/${selectedNoteId}` : 'http://localhost:5000/api/notes';
    const method = selectedNoteId ? 'PUT' : 'POST';
    try {
      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(noteData)
      });

      const data = await response.json();
      if (response.ok) {
        console.log("Note saved:", data);
      } else {
        console.error("Error saving note:", data.error);
      }
    } catch (error) {
      console.error("Error saving note:", error);
    }
  };

  const renderLink = (props) => {
    const { children, href } = props;

    if (href.startsWith('/note/')) {
      const noteId = href.replace('/note/', '');
      return <a href="#" onClick={() => setSelectedNoteId(noteId)}>{children}</a>;
    }

    // Handle custom [[note title]] links
    if (href.startsWith('[[note')) {
      const noteTitle = href.replace('[[note ', '').replace(']]', '');
      return <a href="#" onClick={() => selectNoteByTitle(noteTitle)}>{children}</a>;
    }

    return <a {...props} />;
  };

  const selectNoteByTitle = async (title) => {
    try {
      const response = await fetch(`http://localhost:5000/api/notes?title=${title}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const data = await response.json();
      if (response.ok && data.note) {
        setSelectedNoteId(data.note.id);
      } else {
        console.error("Note not found:", data.error);
      }
    } catch (error) {
      console.error("Error selecting note:", error);
    }
  };

  const handleInsertLink = () => {
    setIsLinkModalOpen(true);  // Open the modal
  };

  const handleNoteSelect = (noteTitle) => {
    setContent(content + ` [[${noteTitle}]]`);
    setIsLinkModalOpen(false);  // Close the modal
  };

  const commandMap = getDefaultCommandMap();
commandMap.link = {
    icon: () => <FaLink style={{ color: 'black' }} />,
    execute: handleInsertLink,
    buttonProps: { 'aria-label': 'Insert link' },
};

const toolbarCommands = getDefaultToolbarCommands();
toolbarCommands[0].push("link");


  return (
    <div className="noteEditor">
      <div className="noteEditor-header">
        <div className="noteEditor-title">Helix AI</div>
        <div className="noteEditor-actions">
          <button onClick={handleSave}>Save Note</button>
          <button onClick={handleGenerateSummary}>Summarize</button>
          <button onClick={handleTranscribe}>Transcribe Note</button>
        </div>
      </div>
      <div className="noteEditor-body">
        <div className="noteEditor-sidebar">
          <NotePicker onNoteSelected={(noteId) => setSelectedNoteId(noteId)} />
          <FileSelector onFileSelect={handleFileSelect} />
        </div>
        <div className="noteEditor-content">
          <ReactMde
            value={content}
            onChange={setContent}
            selectedTab={selectedTab}
            onTabChange={setSelectedTab}
            commands={commandMap}
            toolbarCommands={toolbarCommands}
            generateMarkdownPreview={(markdown) =>
              Promise.resolve(
                <ReactMarkdown
                  components={{
                    a: renderLink
                  }}
                  remarkPlugins={[remarkGfm]}
                  transformLinkUri={(uri) => {
                    const customLinkPattern = /\[\[(.*?)\]\]/;
                    if (customLinkPattern.test(uri)) {
                      return uri.replace(customLinkPattern, '/note/$1');
                    }
                    return uri;
                  }}
                >
                  {markdown}
                </ReactMarkdown>
              )
            }
          />
        </div>
      </div>
      <NoteLinkModal
        isOpen={isLinkModalOpen}
        onClose={() => setIsLinkModalOpen(false)}
        onNoteSelect={handleNoteSelect}
        token={token}
      />
    </div>
  );
}

export default NoteEditor;
