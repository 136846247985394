import React from 'react';
import '../ToolBar.css';

export const FilesInTag = ({
  selectedTag,
  isFilesWithinTagsLoading,
  filesWithinTagsError,
  userFilesWithinTag,
  selectedFile,
  handleFileSelect
}) => {
  return (
    <div className="files-in-tag-container">
      {selectedTag && (
        <>
          <h4>Files inside: {selectedTag}</h4>
          {isFilesWithinTagsLoading ? (
            <p>Loading files...</p>
          ) : filesWithinTagsError ? (
            <p>{filesWithinTagsError}</p>
          ) : (
            <div className="individual-files">
              {userFilesWithinTag.length > 0 ? (
                userFilesWithinTag.map((file, index) => (
                  <div
                    key={index}
                    className={`file-container ${selectedFile && selectedFile.file_name === file.file_name ? 'selected' : ''}`}
                    onClick={() => handleFileSelect(file)}
                  >
                    {file.file_name}
                  </div>
                ))
              ) : (
                <p>No files found in this tag.</p>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default FilesInTag;