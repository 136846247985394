import React, { useState, useEffect } from 'react';

function NoteLinkModal({ isOpen, onClose, onSelect, token }) {
  const [notes, setNotes] = useState([]);

  useEffect(() => {
    if (isOpen) {
      fetchNotes();
    }
  }, [isOpen]);

  const fetchNotes = async () => {
    try {
      const response = await fetch('http://localhost:5000/api/notes', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const data = await response.json();
      if (response.ok) {
        setNotes(data.notes);
      } else {
        console.error("Error fetching notes:", data.error);
      }
    } catch (error) {
      console.error("Error fetching notes:", error);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={onClose}>&times;</span>
        <h2>Select a Note to Link</h2>
        <ul>
          {notes.map(note => (
            <li key={note.id} onClick={() => onSelect(note.title)}>
              {note.title}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default NoteLinkModal;
