// Popup.js
import React, { useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import Login from './Login';
import Register from './Register';
import './auth.css';

const Popup = () => {
  const location = useLocation();
  const [isLogin, setIsLogin] = useState(true);
  const { isAuthenticated } = useContext(AuthContext);
  const showPopupRoutes = ['/chat']; // Add other routes if needed
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  if (isAuthenticated || !showPopupRoutes.includes(location.pathname)) {
    return null; // Don't show popup if user is authenticated or route is not in the list
  }
 return (
    <div className="popup-overlay">
      <div className="popup-content">
        {/* Exit button */}
        <div className="exit-button" style={{ position: 'absolute', top: '10px', right: '10px' }}>
          <a href="/" style={{ textDecoration: 'none'}}>X</a>
        </div>

        {/* Rest of your component */}
        {isLogin ? (
          <>
            <Login />
            <div className="switch-auth-method" onClick={() => setIsLogin(false)}>
              Don't have an account? Sign up
            </div>
          </>
        ) : (
          <>
            <Register />
            <div className="switch-auth-method" onClick={() => setIsLogin(true)}>
              Already have an account? Login
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Popup;


