
//this action is to set the variable true or false to allow
//display
export const setSherpaToolActive = () => {
  return {
  type: 'SET_SHERPA_TOOL_ACTIVE',
  };
};
export const setSherpaToolInactive = () => {
  return {
  type: 'SET_SHERPA_TOOL_INACTIVE',
  };
};
export const setSherpaToolSessionID = (sessionID) => {
  return {
  type: 'SET_SHERPA_TOOL_SESSION_ID',
  sessionID,
  };
}