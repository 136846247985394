// Additional Action Types for chatPDFMessages and chatPDFResponses
export const ADD_CHAT_PDF_MESSAGE = 'ADD_CHAT_PDF_MESSAGE';
export const ADD_CHAT_PDF_RESPONSE = 'ADD_CHAT_PDF_RESPONSE';
export const CLEAR_CHAT_PDF_MESSAGES = 'CLEAR_CHAT_PDF_MESSAGES';
export const CLEAR_CHAT_PDF_RESPONSES = 'CLEAR_CHAT_PDF_RESPONSES';

// Action Creators for chatPDFMessages
export const addChatPDFMessage = message => ({
  type: ADD_CHAT_PDF_MESSAGE,
  payload: message,
});

export const clearChatPDFMessages = () => ({
  type: CLEAR_CHAT_PDF_MESSAGES,
});

// Action Creators for chatPDFResponses
export const addChatPDFResponse = response => ({
  type: ADD_CHAT_PDF_RESPONSE,
  payload: response,
});

export const clearChatPDFResponses = () => ({
  type: CLEAR_CHAT_PDF_RESPONSES,
});
