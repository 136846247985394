import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const AnswerQuestion = () => {
    const [questionData, setQuestionData] = useState(null);
    const query = useQuery();
    const userId = query.get('user_id');
    const questionId = query.get('question_id');

    useEffect(() => {
        const fetchQuestion = async () => {
            try {
                const response = await axios.get(`localhost:5000/api/answer`, {
                    params: { user_id: userId, question_id: questionId },
                });
                setQuestionData(response.data);
            } catch (error) {
                console.error('Error fetching question data:', error);
            }
        };

        fetchQuestion();
    }, [userId, questionId]);

    if (!questionData) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <h1>{questionData.question}</h1>
            <ul>
                {questionData.choices.map((choice, index) => (
                    <li key={index}>{choice}</li>
                ))}
            </ul>
            {/* Add form or functionality for submitting the answer */}
        </div>
    );
};

export default AnswerQuestion;