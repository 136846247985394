import React from 'react';
import { useParams } from 'react-router-dom';
import blogPosts from './blogData'; // Import your blog data
import './Blog.css';
import Footer from '../../Components/Footer/Footer';

const BlogPost = () => {
  const { blogId } = useParams();
  const post = blogPosts.find(p => p.id === blogId);

  if (!post) {
    return <div>Blog post not found</div>;
  }

  // Function to render content blocks
  const renderContentBlocks = () => {
    const contentBlocks = [];

    for (let i = 1; i <= Object.keys(post).length; i++) {
      const content = post[`content${i}`];
      const image = post[`image${i}`];
      const contentTitle = post[`contentTitle${i}`]

      if (contentTitle) {
        contentBlocks.push(<h3 key={`contentTitle-${i}`} className="blog-content-title">{contentTitle}</h3>);
      }
      if (content) {
        contentBlocks.push(<p key={`content-${i}`} className="blog-content-paragraph">{content}</p>);
      }
      if (image) {
        contentBlocks.push(<img key={`image-${i}`} className="blog-image" src={image} alt="blog post"/>);
      }
    }

    return contentBlocks;
  };

  return (
<div>
    <div className="blog-background">
      <div className="blog-title-section">
          <h1>{post.title}</h1>
          {post.subtitle && <h2 className="blog-subtitle">{post.subtitle}</h2>}
      </div>
      <div className="blog-author-section">
        <h3>By: {post.author}</h3>
      </div>
      <div className="blog-content-section">
        {renderContentBlocks()}
      </div>
    </div>
    <footer>
        <Footer />
    </footer>
</div>
  );
};

export default BlogPost;

