import React, { useState } from 'react';
import './EmailCaptureForm.css';

const EmailCaptureForm = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/subscribe`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        const successMessage = 'Email successfully captured';
        setMessage(successMessage);
        console.log(successMessage);
        setEmail('');
      } else {
        const errorMessage = 'Failed to capture email';
        setMessage(errorMessage);
        console.log(errorMessage);
      }
    } catch (error) {
      const errorMessage = 'An error occurred';
      setMessage(errorMessage);
      console.log(errorMessage, error);
    }
  };

  return (
    <div className="email-capture-main">
    <div className="email-capture-container">
    <div className="email-capture-round">
      <h2>Stay Updated</h2>
      <p>Subscribe to our newsletter to get the latest updates.</p>
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button type="submit">Subscribe</button>
      </form>
      {message && <p className="email-capture-message">{message}</p>}
    </div>
    </div>
    </div>
  );
};

export default EmailCaptureForm;

