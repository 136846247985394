import React, { useState, useEffect } from "react";
import ReactDOM from 'react-dom';
//import { loadStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from "./CheckoutForm";
import './Payments.css';
import { SubscriptionBox } from './Pricing.jsx';
import { useHistory} from 'react-router-dom';
import { useAuth } from '../../Components/User_auth/AuthContext';



const stripePromise = loadStripe('pk_test_51Nq5KXDYJJli8wN8qMYDX4DaMYat4NF48bbHWQnTkYLjLXSdW2EO2iEVOLQCvMxOxkIQygRInhjnLHHHHN6o0fXa00Rw18cVtX');




 const PaymentForm = () => {
  const [clientSecret, setClientSecret] = useState("");
  const selectedPlan = localStorage.getItem('selectedPlan');
  const { isAuthenticated, token } = useAuth();


  useEffect(() => {
    console.log("Making a fetch request to create payment intent. Plan:", selectedPlan);
    fetch(`${process.env.REACT_APP_API_URL}/create-payment-intent`, {
      method: "POST",
      headers: { "Content-Type": "application/json",
       "Authorization": `Bearer ${token}`
       },
      body: JSON.stringify({ plan: selectedPlan }),
    })
    .then((res) => {
      console.log("Received response from server:", res);
      return res.json();
    })
    .then((data) => {
      console.log("Received data from server:", data);
      setClientSecret(data.clientSecret);
    });
}, []);

  return (
    <div className="flex-container">
      <div className="checkout-pricing-container">
        {selectedPlan === 'starter' && (
          <div className="checkout-pricings">
            <div className="checkout-starter">
             <div className="checkout-plan-details">
              <h2>Starter Plan</h2>
              <h3>$8.99</h3>
              <h5> Includes:</h5>
              <h4>✔ 1,000 pages of document upload</h4>
              <h4>✔ 500 AI chats per month</h4>
              <h4>✔ Access to Notes</h4>
              <h4>✔ 200 note summaries per month</h4>
             </div>
            </div>
          </div>
        )}
        {selectedPlan === 'pro' && (
          <div className="checkout-pricings">
            <div className="checkout-pro">
             <div className="checkout-plan-details">
              <h2>Pro Plan</h2>
              <h3>$15.99</h3>
              <h5> Includes:</h5>
              <h4>✔ 20,000 pages of document upload</h4>
              <h4>✔ 1,500 AI chats per month</h4>
              <h4>✔ Access to Notes</h4>
              <h4>✔ 500 note summaries per month</h4>
             </div>
            </div>
          </div>
        )}
        {selectedPlan === 'Premium' && (
          <div className="checkout-pricings">
            <div className="checkout-Premium">
             <div className="checkout-plan-details">
              <h2>Premium Plan</h2>
              <h3>$39.99</h3>
              <h5> Includes:</h5>
              <h4>✔ 100,000 pages of document upload</h4>
              <h4>✔ Unlimited AI chats per month</h4>
              <h4>✔ Access to Notes</h4>
              <h4>✔ Unlimited note summaries per month</h4>
              <h4>✔ Guaranteed access to all upcoming features</h4>
             </div>
            </div>
          </div>
        )}
        {/* Add conditions for other plans if needed */}
      </div>
      <div className="payment-form">
        {clientSecret && (
          <Elements stripe={stripePromise} options={{clientSecret: clientSecret}}>
            <CheckoutForm clientSecret={clientSecret} />
          </Elements>
        )}
      </div>
    </div>
  );
}


export default PaymentForm;
