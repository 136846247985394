import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './UserInput.css';
import uploadIcon from '../../assets/PDF-upload.png'
import Submit from '../../assets/Submit.png'
import { useAuth } from '../../Components/User_auth/AuthContext';
import { addMessage, addResponse } from '../../redux/actions/chatActions'; // Adjust the import path as needed
import { useDispatch } from 'react-redux';

const adjustHeight = (element) => {
  element.style.height = '30px'; // Reset the height
  element.style.height = `${Math.min(element.scrollHeight, 300)}px`;
};

function SherpaUserInput({ sessionId, openModal, selectedOption }) {
  const [message, setMessage] = useState('');
  const dispatch = useDispatch(); // Use useDispatch hook
  const { token } = useAuth();

  useEffect(() => {
    const textarea = document.querySelector('.message-input');
    if (textarea) {
      adjustHeight(textarea);
    }
  }, [message]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setMessage('');

    // Dispatch the addMessage action to the Redux store
    dispatch(addMessage(message));

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/sherpa_chat`, {
        message,
        session_id: sessionId,
        selectedOption
      }, { headers: { 'Authorization': `Bearer ${token}` } });

      // Dispatch the addResponse action with the response data
      dispatch(addResponse(response.data));
    } catch (error) {
      console.error('Error:', error);
      let errorMessage = "An error occurred, please try again later.";

      if (error.response) {
        errorMessage = error.response.status === 500
          ? "An error occurred, try resubmitting your question again"
          : error.response.status === 401
          ? "Please refresh your page and log in again."
          : "An error occurred with your request.";
      } else if (error.request) {
        errorMessage = "Network Error. Please check your connection and try again.";
      }

      // Dispatch the addResponse action with the error message
      dispatch(addResponse({ answer: errorMessage, source: "No sources available" }));
    }
  };

  // html
  return (
    <div className="userInput-root">
      <form onSubmit={handleSubmit}>
        <div className="input-group">
          {/* Button with the PDF_PNG icon */}
          <div className= "pdf-button-container">
          <button className="pdf-button" type="button" onClick={openModal}>
            <img src={uploadIcon} alt="uploadIcon" />
          </button>
          </div>
         <textarea
            className="message-input"
            type="text"
            value={message}
            onChange={(e) => {
              setMessage(e.target.value);
              adjustHeight(e.target);
            }}
            onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey && message.trim()) {
                    e.preventDefault();
                    handleSubmit(e);
                  }
                }}
            placeholder="Enter your message"
        />

          {/* The button is disabled if the trimmed message is empty */}
          <button className="input-button" type="submit" disabled={!message.trim()}>
            <img src={Submit} alt="SubmitIcon" />
          </button>
        </div>
      </form>
    </div>
  );
};

export default SherpaUserInput;